import React from "react";
import { IChallengeProps } from "./ChallengeInterface";
import "./challenges.scss";
import Button from "../../../Button/Button";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import { Pre, Line, LineNo, LineContent } from "./code-block";

const MidLevelChallenge = ({
  handleChange,
  selectedAnswer,
  question,
  answers,
  handleSubmit,
  handleBack,
  codeLanguage,
}: IChallengeProps) => {
  const getChallengeAnswerClass = (currAnswerId) => {
    if (selectedAnswer.length > 0 && selectedAnswer.includes(currAnswerId)) {
      return "challenge-button-selected";
    } else {
      return "";
    }
  };

  const renderAnswers = () => (
    <div className="challenge-answers-container">
      <div className="challenge-answers-row">
        {answers?.map((answer, index) => (
          <button
            key={`code-hunt-answer-${index}`}
            className={[
              "challenge-button",
              getChallengeAnswerClass(answer.answerId),
            ]
              .filter(Boolean)
              .join(" ")}
            onClick={() => handleChange(answer.answerId)}
          >
            <div className="challenge-button-answer">{answer.answer}</div>
            <div className="challenge-button-circle">
              <div className="challenge-button-circle__inner"></div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
  return (
    <div className="section-container">
      <div className="challenge-question">
        <div className="question-container">
          <p>
            Inspect the code block below and select{" "}
            <span className="bold">ALL</span> security vulnerabilities that
            apply. Help us destroy the virus!
          </p>
        </div>
        <div className="code-hunt-code-container">
          <Highlight
            {...defaultProps}
            theme={theme}
            code={question}
            language={codeLanguage}
          >
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <Pre
                className={className + " code-hunt-block-content"}
                style={style}
              >
                {tokens.map((line, i) => (
                  <Line key={i} {...getLineProps({ line, key: i })}>
                    <LineNo>{i + 1}</LineNo>
                    <LineContent>
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </LineContent>
                  </Line>
                ))}
              </Pre>
            )}
          </Highlight>
        </div>
      </div>
      <div>{renderAnswers()}</div>
      <div className="challenge-buttons-container">
        <button
          onClick={() => handleBack(-1)}
          value="Back"
          className="code-hunt-back-button"
        >
          Back
        </button>
        <Button
          type="button"
          disabled={selectedAnswer.length === 0}
          onClick={() => handleSubmit()}
          value="Submit"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default MidLevelChallenge;
