import React, { useState } from "react";
import "./ChatWindow.scss";
import ChatInput from "../ChatInput/ChatInput";
import LoadingSpinner from "../../LoadingSpinner";
import { getDataImage } from "../../../utils/imageUtils";

type ChatWindowProps = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  participantData: any;
  participantIsLoading: boolean;
};

export type IChatEntry = {
  chatId: number;
  chatType: string;
  chatMessage: string;
};

const getChatClassName = (input: string) => {
  switch (input) {
    case "user": {
      return "user-message";
    }
    case "bot": {
      return "bot-message";
    }
    default: {
      return "greeting-message";
    }
  }
};

const ChatWindow: React.FC<ChatWindowProps> = ({
  visible,
  setVisible,
  participantData,
  participantIsLoading,
}): React.ReactElement => {
  const [chatLog, setChatLog] = useState([
    {
      chatId: 0,
      chatType: "default",
      chatMessage: "Hello there. How can we help?",
    },
  ] as IChatEntry[]);
  const [loading, setLoading] = useState(false);

  return (
    <div className={`chat-border ${!visible && "hidden"}`}>
      <div className="chat-close-button" onClick={() => setVisible(false)} />
      <div className="chat-window">
        <ChatInput
          chatLog={chatLog}
          setChatLog={setChatLog}
          setLoading={setLoading}
        />
        {loading && (
          <div className="chat-message">
            <span className="chat-avi-container">
              <div className="bot-avi"></div>
            </span>
            <div className="bot-message">
              <div className="chat-dot-container">
                <div className="chat-dot-flashing" />
              </div>
            </div>
          </div>
        )}
        {chatLog.map((message) => (
          <div key={message.chatId}>
            <div className="chat-message">
              {message.chatType === "bot" && (
                <span className="chat-avi-container">
                  <div className="bot-avi"></div>
                </span>
              )}
              <div
                className={`${getChatClassName(message.chatType)}`}
                key={message.chatId}
              >
                {message.chatMessage}
              </div>
              {message.chatType === "user" && (
                <span className="chat-avi-container">
                  {participantIsLoading ? (
                    <LoadingSpinner
                      isLoading={participantIsLoading}
                      color={"#ffdbdf"}
                      size="big"
                    />
                  ) : (
                    <img
                      src={getDataImage(participantData?.image)}
                      alt="profile pic"
                      height="50"
                      width="50"
                      className={`user-avi ${!participantData?.image}`}
                    />
                  )}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ChatWindow;
