import React from "react";
import useWindowSize from "../../utils/useWindowSize";
import "./PageWrapper.scss";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import PageBanner from "../PageBanner/PageBanner";
import { Event } from "../../utils/consts/event";
import Announcements from "../Announcements/Announcements";
import Footer from "../Footer/Footer";

const event = Event;

type PageWrapperProps = {
  bannerImage?: string;
  bannerContent?: React.ReactElement;
  children: any;
  showBug?: boolean;
};

const PageWrapper: React.FC<PageWrapperProps> = ({
  bannerImage,
  bannerContent,
  children,
  showBug,
}): React.ReactElement => {
  const { width } = useWindowSize();

  return (
    <div className="page-wrapper">
      <div
        className={`page-wrapper-content page-wrapper-content-${getResponsiveClassName(
          width
        )}`}
      >
        {!!event?.announcements?.length && (
          <Announcements announcementData={event.announcements} />
        )}
        <div className="page-wrapper__banner">
          {bannerImage && (
            <PageBanner bannerImage={bannerImage} showBug={showBug} />
          )}
          {!!bannerContent && bannerContent}
        </div>
        <div className="background-wrapper">
          <div className="page-wrapper__content-container">{children}</div>
          <ScrollToTopButton />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
