import React, { useRef } from "react";
import "./ChatInput.scss";
import { IChatEntry } from "../ChatWindow/ChatWindow";
import { getHintMessage } from "../../../ApiHelper";

type ChatInputProps = {
  chatLog: IChatEntry[];
  setChatLog: React.Dispatch<React.SetStateAction<IChatEntry[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

interface IFormElements extends HTMLFormControlsCollection {
  chatInput: HTMLInputElement;
}
interface IUsernameFormElement extends HTMLFormElement {
  readonly elements: IFormElements;
}

const ChatInput: React.FC<ChatInputProps> = ({
  chatLog,
  setChatLog,
  setLoading,
}): React.ReactElement => {
  const formRef = useRef();

  const getHint = async (message: string) => {
    const result = await getHintMessage(message);
    return result.hint;
  };

  return (
    <form
      ref={formRef}
      onSubmit={async (e: React.FormEvent<IUsernameFormElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
          chatMessage: { value: string };
        };
        const message = e.currentTarget.elements.chatInput.value;
        setChatLog((chatLog) => [
          {
            chatId: chatLog.length,
            chatType: "user",
            chatMessage: message,
          } as IChatEntry,
          ...chatLog,
        ]);
        setLoading(true);
        target.chatMessage.value = "";
        const hint = await getHint(message);
        setLoading(false);
        setChatLog((chatLog) => [
          {
            chatId: chatLog.length,
            chatType: "bot",
            chatMessage: `${hint}`,
          } as IChatEntry,
          ...chatLog,
        ]);
      }}
    >
      <div className="chat-input-container">
        <input
          id="chatInput"
          type="text"
          name="chatMessage"
          placeholder="Type in your question"
          className="chat-input-text"
        ></input>
        <div>
          <input
            className="chat-submit-button"
            type="submit"
            value="send chat"
          />
        </div>
      </div>
    </form>
  );
};
export default ChatInput;
