import React, { useState } from "react";
import "./SpotTheFake.scss";
import { queryCache, useMutation, useQuery } from "react-query";
import { getSpotTheFake, saveSpotTheFake } from "../../ApiHelper";
import { SpotTheFake } from "../../models/spotTheFake.model";
import LoadingSpinner from "../LoadingSpinner";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import Button from "../Button/Button";
import BottomCog from "../../assets/images/hacktober-23-hackathon-bottom-left-cog.png";
import TopCog from "../../assets/images/hacktober-23-hackathon-top-right-cog.png";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const SpotTheFakeComponent: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const viewport =
    width <= MOBILE_MAX ? "mobile" : width <= TABLET_MAX ? "tablet" : "desktop";

  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const { data: spotTheFakeData, status } = useQuery<SpotTheFake | undefined>(
    ["spotthefake"],
    getSpotTheFake
  );

  const [mutate, { status: submitStatus }] = useMutation(saveSpotTheFake, {
    onSettled: () => {
      queryCache.invalidateQueries("spotthefake");
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    },
  });

  const spotTheFakeRequest = (spotTheFakeId, answerId, user) => ({
    id: spotTheFakeId,
    answerId: answerId,
    user: user,
  });

  const handleSubmitClick = async () => {
    if (!selectedAnswerId) return;

    const spotTheFakePOST = spotTheFakeRequest(
      spotTheFakeData.id,
      selectedAnswerId,
      ""
    );
    setSelectedAnswerId(null);

    mutate(spotTheFakePOST);
  };

  const handleAnswerChoiceClick = (currAnswerChoice) => {
    setSelectedAnswerId(currAnswerChoice);
  };

  const getSpotTheFakeClass = (currAnswerId) => {
    const className = "spot-the-fake-answer-choice-";
    if (spotTheFakeData?.userAnswer) {
      const { correctAnswerId, answerId, isCorrect } =
        spotTheFakeData.userAnswer;
      if (correctAnswerId === currAnswerId) return `${className}correct`;
      else if (!isCorrect && currAnswerId === answerId)
        return `${className}wrong`;
    } else if (currAnswerId === selectedAnswerId) {
      return `${className}selected`;
    }

    return "";
  };

  const getAnswerExplanation = () => {
    if (spotTheFakeData.userAnswer) {
      const { explanation, isCorrect } = spotTheFakeData.userAnswer;
      const text = isCorrect ? "You got it right!" : "That's not it.";
      return `${text} ${explanation}`;
    }
  };
  return (
    <div className="spot-the-fake-container">
      <img src={BottomCog} alt="bg" className="bg-cog bottom-left"></img>
      <img src={TopCog} alt="bg" className="bg-cog top-right"></img>
      <LoadingSpinner isLoading={status === "loading"} size="big">
        {typeof spotTheFakeData !== "undefined" && (
          <>
            <div className="spot-the-fake-subTitle">
              Think you have what it takes to tell Human art from AI art? Test
              it here!
            </div>
            <img
              src={spotTheFakeData.description}
              className="spot-the-fake-image"
              alt="spot the fake"
            />
            <div className="spot-the-fake-question">
              Was the artwork above generated by AI?
            </div>
            <div className="spot-the-fake-answers-container">
              {spotTheFakeData.answers.map((answer) => {
                return (
                  <button
                    key={answer.answerId}
                    className={[
                      "spot-the-fake-answer-choice",
                      getSpotTheFakeClass(answer.answerId),
                    ]
                      .filter(Boolean)
                      .join(" ")}
                    disabled={!!spotTheFakeData.userAnswer}
                    onClick={() => handleAnswerChoiceClick(answer.answerId)}
                  >
                    <div className="spot-the-fake-answer-choice-answer">
                      {answer.description}
                    </div>
                    <div className="spot-the-fake-answer-choice-circle">
                      <div className="spot-the-fake-answer-choice-circle__inner"></div>
                    </div>
                  </button>
                );
              })}
            </div>
            <div className={`spot-the-fake-submission-container ${viewport}`}>
              <LoadingSpinner
                isLoading={submitStatus === "loading"}
                size="large"
              >
                {!spotTheFakeData?.userAnswer ? (
                  <Button
                    type="button"
                    disabled={
                      !!spotTheFakeData?.userAnswer || !selectedAnswerId
                    }
                    onClick={() => handleSubmitClick()}
                    value="Submit"
                  />
                ) : (
                  <div className="spot-the-fake-message">
                    {getAnswerExplanation()}
                  </div>
                )}
              </LoadingSpinner>
            </div>
          </>
        )}
      </LoadingSpinner>
    </div>
  );
};

export default SpotTheFakeComponent;
