/* eslint-disable react/no-unescaped-entities */
import React from "react";
import "./OtherWaysToParticipate.scss";
import LinkButton from "../../components/LinkButton/LinkButton";
import Card from "../Card/Card";
import Tooltip from "../Tooltip/Tooltip";
import { EButtonStyle } from "../../utils/buttonUtils";
//import { cleanup } from "@testing-library/react";
import outlinedIcon from "../../assets/images/outlined-icon.png";
import useWindowSize from "../../utils/useWindowSize";
import SectionHeader from "../SectionHeader/SectionHeader";

const CLAIM_POINTS_PAGE = "/events";
const MAIL_TO_ZENITH = "mailto:zenith@slalom.com";
const MAIL_TO_SECURITY = "mailto:security@slalom.com";
const HACK_PAGE = "/hacker";

interface CardProps {
  title: string;
  points?: number;
  description: React.ReactElement;
  clue: React.ReactElement;
  includeClue: boolean;
  buttonValue?: string;
  buttonHref?: string;
}

const OtherWaysToParticipateCard: React.FC<CardProps> = ({
  title,
  points,
  description,
  clue,
  includeClue,
  buttonValue,
  buttonHref,
}): React.ReactElement => (
  <Card>
    <div>
      <div className="tile-title">
        {title}
        {includeClue && (
          <div className="tile-tooltip">
            <Tooltip>{clue}</Tooltip>
          </div>
        )}
      </div>
      {points && (
        <div className="tile-points">
          <img src={outlinedIcon} alt="points" />
          <div className="points-value">{points} Points</div>
        </div>
      )}
      <div className="tile-description">{description}</div>
    </div>
    <div></div>
    {buttonValue && buttonHref && (
      <div className="tile-button-container">
        <LinkButton
          text={buttonValue}
          style={EButtonStyle.PRIMARY}
          href={buttonHref}
          newTab={true}
        />
      </div>
    )}
  </Card>
);

const signUpForOnePasswordDescription = () => (
  <p className="tile-descriptions">
    Request an invite from{" "}
    <a href={MAIL_TO_SECURITY} target="_blank" rel="noopener noreferrer">
      security@slalom.com
    </a>{" "}
    and mention it is part of Hacktober in order to earn points!
    <br></br>
    <br></br>
    <i>
      If you have already signed up for 1Password, your points have already been
      added.
    </i>
    <br></br>
    <br></br>
    <br></br>
  </p>
);

const HiddenSecuryetiDescription = () => (
  <p className="tile-descriptions">
    Think you've got what it takes to find our hidden Securyeti? Find him and
    follow the instructions you see to get some extra points!
    <br></br>
    <br></br>
    <i>
      <b>Hint: </b>You might want to inspect the page closely to find him.
    </i>
  </p>
);

const SeeSomethingSlaySomethingDescription = () => (
  <p className="tile-descriptions">
    Catch a physical security risk in a TV show or movie, out in public, or
    online, and submit proof for points! Wondering what counts as a security
    risk? Check out our survival tips and the Know the Code tips on our FAQs
    page. Points will be added by the end of the month.
  </p>
);

const DontFallForThePhishDescription = () => (
  <p className="tile-descriptions">
    We're testing your skills throughout the month with random, surprise
    phishing emails. Use the <b>Report Phish</b> button in Outlook to report
    them and you'll earn points! Points will be added at the end of the month.
  </p>
);

const CaughtABugDescription = () => (
  <p className="tile-descriptions">
    If you see something, say something -- and that goes for bugs. If you notice
    a bug on the site, send it in to zenith@slalom.com to earn points!
  </p>
);

const DiscoverHackerPageDescription = () => (
  <p className="tile-descriptions">
    Explore our incredible AR time capsule, help Spot the Fake, answer the Code
    Hunt, join a Hackathon, and more on our brand new Hacker Page!
  </p>
);

const HiddenSecuryetiClue = () => (
  <p className="tile-descriptions">
    You might want to inspect the page closely to find him.
  </p>
);

interface OtherwaysToParticipateCardContent {
  title: string;
  points?: number;
  descriptionComponent: () => React.ReactElement;
  clue: () => React.ReactElement;
  includeClue: boolean;
  button?: { value: string; href: string };
}
const cardContent: OtherwaysToParticipateCardContent[] = [
  {
    title: "Join Slalom on 1Password",
    points: 300,
    descriptionComponent: signUpForOnePasswordDescription,
    clue: HiddenSecuryetiDescription,
    includeClue: false,
    button: { value: "Join 1Password", href: MAIL_TO_SECURITY },
  },
  {
    title: "Find the Hidden Yeti!",
    points: 200,
    descriptionComponent: HiddenSecuryetiDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Claim Points", href: CLAIM_POINTS_PAGE },
  },
  {
    title: "See Something, Slay Something!",
    points: 100,
    descriptionComponent: SeeSomethingSlaySomethingDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Submit", href: MAIL_TO_ZENITH },
  },
  {
    title: "Don't Fall for the Phish!",
    points: 200,
    descriptionComponent: DontFallForThePhishDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
  },
  {
    title: "Caught a Bug?",
    points: 200,
    descriptionComponent: CaughtABugDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Submit", href: MAIL_TO_ZENITH },
  },
  {
    title: "Discover our Hacker page!",
    descriptionComponent: DiscoverHackerPageDescription,
    clue: HiddenSecuryetiClue,
    includeClue: false,
    button: { value: "Hack", href: HACK_PAGE },
  },
];

const OtherWaysToParticipate = () => {
  const { width } = useWindowSize();
  return (
    <div className="other-ways-to-earn-page section-container">
      <SectionHeader
        sectionTitleString={"Other Ways to Earn Points"}
        width={width}
      ></SectionHeader>
      <div className="other-ways-to-earn-container">
        {cardContent.map(
          (
            { title, points, descriptionComponent, clue, includeClue, button },
            idx
          ) => (
            <OtherWaysToParticipateCard
              key={`other-ways-to-earn-card-${idx}`}
              title={title}
              points={points}
              description={descriptionComponent()}
              clue={clue()}
              includeClue={includeClue}
              buttonValue={button?.value}
              buttonHref={button?.href}
            />
          )
        )}
      </div>
    </div>
  );
};

export default OtherWaysToParticipate;
