import React, { FC } from "react";
import HackathonSection from "./HackathonSection/HackathonSection";

type Props = {};

const HackathonsSection: FC<Props> = () => {
  return (
    <>
      <HackathonSection
        title="SECURE LEGENDS AWS GAMEDAY"
        aboutContent={
          <>
            <p>
              Trick or treat! This Gameday is unlike any other, as you embark on
              several different quests to become the ultimate Secure Legend!
              Quests include:
            </p>
            <h3>Credentials Management</h3>
            <p>
              Credentials for Unicorn Rentals have been compromised. Players
              will have to identify that user that encrypted some of the Unicorn
              instances and update that users access. The player will also
              utilize AWS Config to make sure it doesn&apos;t happen again.
            </p>
            <h3>Data Classification and Protection</h3>
            <p>
              Personal identity information is being leaked from Unicorn
              Rentals! The player will have to create a custom identifier and
              then apply it to the unicorn files stored in S3. They will then
              need to determine the source of the leak and put a stop to it.
            </p>
            <h3>Forensics Analysis</h3>
            <p>
              Unicorn Instances have been compromised! The user will have to
              identify the compromised instance and then cordon it to run
              analysis. They will use AWS CloudShell and the command line to
              determine where the instances were compromised and fix any issues.
            </p>
            <h3>Infrastructure Protection</h3>
            <p>
              Unicorn sites are being attacked! Players will have to stop the
              multitude of attacks using the AWS WAF service.
            </p>
            <h3>Vulnerability Management</h3>
            <p>
              The Unicorn code pipeline has been attacked! Users will use tools
              like Hadolint and Anchore, along with AWS Security Hub to fix the
              pipeline and get a new container image built!
            </p>
          </>
        }
        howToParticipateContent={
          <>
            <p>
              As with most AWS gamedays, there is not a ton of direction as it
              is meant to resemble real-world scenarios. Teams will be
              randomized and assigned at the beginning of the Gameday by AWS. A
              good knowledge of the following AWS services is recommended:
            </p>
            <ul>
              <li>AWS Lambda</li>
              <li>AWS Security Hub</li>
              <li>AWS CodePipeline</li>
              <li>Amazon EC2</li>
              <li>Amazon Elastic Load Balancer</li>
              <li>
                Familiarity with the AWS Well-Architected Framework advised
              </li>
            </ul>
          </>
        }
        signUpContent={
          <>
            <p>
              Sign up to participate! Sign ups will be available through October
              15th, and the Gameday will take place Oct 24th. Winners will be
              announced October 27th. An additional date may be added if there
              are a large amount of participants!
            </p>
            <h3>Prizes</h3>
            <p>All participants will receive 200 points for participating.</p>
            <p>
              The FIRST PLACE team will receive AWS gift cards and also an extra
              500 points!
            </p>
          </>
        }
        signUpUrl="https://sparkthink.slalom.com/survey/s/148f8b7c-3484-4471-b318-ad0de4076e06"
      ></HackathonSection>

      <HackathonSection
        title="GITGUARDIAN HUNT THE HACKER"
        aboutContent={
          <>
            <p>
              Immerse yourself in this engaging mix of blue team and red team
              exercises, with a primary focus on code security and secret
              management.
            </p>
            <p>
              As an appsec engineer, you’ll have access to a Gitlab with
              multiple repositories under automated and periodical attacks. Your
              mission is to secure the environment by identifying repositories
              that are under attack, uncovering potentially compromised secrets,
              and effectively blocking these malicious attempts.
            </p>
            <p>
              Feel free to jump straight into the challenges section, where
              you’ll find the first task that introduces you to the environment
              and sets you on your path to safeguarding your repositories.
              Engage your skills, sharpen your security prowess, and embark on
              an exciting journey to defend against hacker intrusions!
            </p>
            <p>Let the hunt begin!</p>
          </>
        }
        howToParticipateContent={
          <>
            <h3>Rules</h3>
            <ol>
              <li>
                This CTF is using an external monitoring system based on
                confidentiality and availability.
              </li>
              <li>
                Changes made to your environment will have consequences on these
                parameters.
              </li>
              <li>
                You start with a 10% confidentiality score and a 100%
                availability score.
              </li>
              <li>
                After being lost, confidentiality and availability points can be
                regained if you manage to fix the situation. Be careful, some
                actions can not be undone !…
              </li>
              <li>
                Challenges with a warning (
                <span role="img" aria-label="warning symbol">
                  ⚠️
                </span>
                ) will tell you when a change to the environment is needed to
                validate the challenge.
              </li>
              <li>
                The goal is to reach 100 % confidentiality while maintaining 100
                % availability.
              </li>
            </ol>
            <p>
              Feel free to jump straight into the challenges section, where
              you’ll find the first task that introduces you to the environment
              and sets you on your path to safeguarding your repositories.
              Engage your skills, sharpen your security prowess, and embark on
              an exciting journey to defend against hacker intrusions!
            </p>
            <p>Let the hunt begin!</p>
          </>
        }
        signUpContent={
          <>
            <p>
              Sign up to participate in our first ever GitGuardian Hackathon!
              Sign ups will be available through October 13th, and the hackathon
              will run from October 16-20th. The registration code to sign up is
              <strong> SLALOM2K23</strong>. Users may continue to compete from
              the 23-27th for less points. Winners will be announced October
              27th.
            </p>
            <h3>Prizes</h3>
            <p>All participants will receive 200 points for participating.</p>
            <p>
              The TOP THREE winners will receive a exclusive prize from
              GitGuardian as well as an extra 300 points!
            </p>
            <p>The FIRST PLACE winner will receive an extra 500 points.</p>
          </>
        }
        signUpUrl="https://ctf.gitguardian.tech/"
      ></HackathonSection>
    </>
  );
};

export default HackathonsSection;
