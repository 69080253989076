import React from "react";
import { IChallengeProps } from "./ChallengeInterface";
import "./challenges.scss";
import Button from "../../../Button/Button";

const LowLevelChallenge = ({
  handleChange,
  selectedAnswer,
  question,
  answers,
  handleSubmit,
  handleBack,
}: IChallengeProps) => {
  const getChallengeAnswerClass = (currAnswerId) => {
    if (selectedAnswer.length > 0 && currAnswerId === selectedAnswer[0]) {
      return "challenge-button-selected";
    } else {
      return "";
    }
  };

  const renderAnswers = () => (
    <div className="challenge-answers-container">
      <div className="challenge-answers-row">
        {answers?.map((answer, index) => (
          <button
            key={`code-hunt-answer-${index}`}
            className={[
              "challenge-button",
              getChallengeAnswerClass(answer.answerId),
            ]
              .filter(Boolean)
              .join(" ")}
            onClick={() => handleChange(answer.answerId)}
          >
            <div className="challenge-button-answer">{answer.answer}</div>
            <div className="challenge-button-circle">
              <div className="challenge-button-circle__inner"></div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <div className="challenge-question">{question}</div>
      <div>{renderAnswers()}</div>
      <div className="challenge-buttons-container">
        <button
          onClick={() => handleBack(-1)}
          value="Back"
          className="code-hunt-back-button"
        >
          Back
        </button>
        <Button
          type="button"
          disabled={selectedAnswer.length === 0}
          onClick={() => handleSubmit()}
          value="Submit"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default LowLevelChallenge;
