import React, { FC } from "react";
import "./HackathonInfoTile.scss";
import useWindowSize from "../../../utils/useWindowSize";
import { getResponsiveClassName } from "../../../utils/responsiveClassHelpers";
import LinkButton from "../../LinkButton/LinkButton";
import Card from "../../Card/Card";
import BottomCog from "../../../assets/images/hacktober-23-hackathon-bottom-left-cog.png";
import TopCog from "../../../assets/images/hacktober-23-hackathon-top-right-cog.png";

type Props = {
  cardTitle: string;
  content: any;
  url: string;
  buttonText: string;
};

const HackathonInfoTile: FC<Props> = ({
  cardTitle,
  content,
  url,
  buttonText,
}) => {
  const { width } = useWindowSize();

  return (
    <Card
      containerClass={`hackathon-info-tile contact-card contact-card-${getResponsiveClassName(
        width
      )} ${cardTitle === "Sign Up" ? "sign-up" : ""}`}
    >
      <img src={BottomCog} alt="bg" className="bg-cog bottom-left"></img>
      <img src={TopCog} alt="bg" className="bg-cog top-right"></img>
      <div className="content-section">
        <div className="contact-section-title">{cardTitle}</div>
        <div
          className={`hackathon about-page-text-body-container ${
            url ? "" : "no-button"
          }`}
        >
          {content}
        </div>
      </div>
      <div></div>{" "}
      {url && (
        <div className="card-bottom-button-container">
          <LinkButton href={url} newTab={true} text={buttonText} />
        </div>
      )}
    </Card>
  );
};

export default HackathonInfoTile;
