import React from "react";
import TeamInfoTile from "../TeamInfoTile/TeamInfoTile";
import "./AboutInfoList.scss";
import useWindowSize from "../../utils/useWindowSize";
import { AboutInfoItem } from "../../utils/interfaces/AboutInfoItem.interface";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

type AboutInfoListProps = {
  participants: AboutInfoItem[];
};
const renderAboutList = (participants: any): JSX.Element => {
  return participants.map((participant, index) => (
    <TeamInfoTile allowFlipTile={false} key={index} participant={participant} />
  ));
};

const aboutInfoListResponsive = (width: number) => {
  return `about-list-${getResponsiveClassName(width)}-component`;
};

const AboutInfoList = ({ participants }: AboutInfoListProps) => {
  const { width } = useWindowSize();
  return (
    <div className={`about-list ${aboutInfoListResponsive(width)}`}>
      {renderAboutList(participants)}
    </div>
  );
};

export default AboutInfoList;
