import React, { useContext } from "react";
import "./HomePage.scss";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import QuizOfTheDay from "../../components/QuizOfTheDay/QuizOfTheDay";
import OtherWaysToParticipate from "../../components/OtherWaysToParticipate";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";

import homeBannerDesktop from "../../assets/images/headers/home/homepage-hero-image.png";
import homeBannerTablet from "../../assets/images/headers/home/homepage-hero-image-tablet.png";
import homeBannerMobile from "../../assets/images/headers/home/homepage-hero-image-mobile.png";
import { ParticipantContext } from "../../App";
import { showWinners } from "../../utils/eventUtil";

const HomePage: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const participant = useContext(ParticipantContext);
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;
  const homeBanner =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;

  return (
    <ScrollToTop>
      <div className="home-page-container">
        <PageWrapper bannerImage={homeBanner}>
          <QuizOfTheDay />
          <Leaderboard
            isLocalLeaderBoard={undefined}
            isWinnersLeaderBoard={showWinners}
            participantData={participant}
          />
          <OtherWaysToParticipate />
        </PageWrapper>
      </div>
    </ScrollToTop>
  );
};
export default HomePage;
