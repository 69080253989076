import React from "react";
import moment from "moment";
import LoadingSpinner from "../../components/LoadingSpinner";
import EventCard from "../../components/EventCard";
import { filteredEvents } from ".";
import { FactActivityTypes } from "../../utils/consts/factActivityTypes";

export enum RelativeTime {
  FUTURE = "future",
  PAST = "past",
}

export interface EventCardGridProps {
  eventsAreLoading: boolean;
  eventsError: string | null;
  eventsData: any[]; // TODO: create an event type (this is not the same as a React event)
  selectedMarkets: string[];
  selectedDays: string[];
  selectedTimes: string[];
  speakerProfiles: any[];
  setSpeakerProfiles: (profiles) => void;
  relativeTimeFilter?: RelativeTime;
}

const EventCardGrid: React.FC<EventCardGridProps> = ({
  eventsAreLoading,
  eventsError,
  eventsData,
  selectedMarkets,
  selectedDays,
  selectedTimes,
  speakerProfiles,
  setSpeakerProfiles,
  relativeTimeFilter = RelativeTime.FUTURE,
}) => {
  return (
    <div className="event-card-grid">
      <LoadingSpinner
        isLoading={eventsAreLoading}
        error={eventsError}
        size="massive"
        color="#443A84"
      >
        {filteredEvents(
          eventsData,
          [],
          selectedMarkets,
          selectedDays,
          selectedTimes
        ).map((event) => {
          const shouldDisplayEvent =
            relativeTimeFilter === RelativeTime.FUTURE
              ? moment(event.endDateTime).isSameOrAfter(moment())
              : moment(event.endDateTime).isBefore(moment());
          // Find the object in the array at event.likeCategories that has an likeTypeId of 1
          const likeStatus = event.likeCategories.find(
            (likeCategory) => likeCategory.likeTypeId === FactActivityTypes.like
          );

          return (
            shouldDisplayEvent && (
              <EventCard
                key={event.eventId}
                {...event}
                speakerProfiles={speakerProfiles}
                setSpeakerProfiles={setSpeakerProfiles}
                isPastEvent={relativeTimeFilter === RelativeTime.PAST}
                eventId={event.eventId}
                isLiked={likeStatus ? likeStatus.liked : false}
                activityLikeId={likeStatus ? likeStatus.likeId : undefined}
              />
            )
          );
        })}
      </LoadingSpinner>
    </div>
  );
};

export default EventCardGrid;
