import React from "react";
import "./NavUserAndPoints.scss";
import Rankings from "../../Rankings";
import LoadingSpinner from "../../LoadingSpinner";
import { getResponsiveClassName } from "../../../utils/responsiveClassHelpers";
import { getDataImage } from "../../../utils/imageUtils";
import { deviceWidthBreakpoints } from "../../../utils/useWindowSize";

type NavUserAndPointsProps = {
  width: number;
  participantData: any;
  participantIsLoading: boolean;
};

const { TABLET_MAX } = deviceWidthBreakpoints;

const NavUserAndPoints: React.FC<NavUserAndPointsProps> = ({
  width,
  participantData,
  participantIsLoading,
}): React.ReactElement => (
  <div className={`nav-user-container-${getResponsiveClassName(width)}`}>
    <Rankings participantData={participantData} />
    {width > TABLET_MAX && (
      <span className="nav-points">
        <span>{participantData?.totalPoints || "0"} Points</span>
      </span>
    )}
    <span className="nav-user">
      {participantIsLoading ? (
        <LoadingSpinner
          isLoading={participantIsLoading}
          color={"#ffdbdf"}
          size="big"
        />
      ) : (
        <img
          src={getDataImage(participantData?.image)}
          alt="profile pic"
          height="50"
          width="50"
          className={`nav-user-img ${!participantData?.image}`}
        />
      )}
    </span>
  </div>
);

export default NavUserAndPoints;
