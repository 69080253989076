import React, { useContext } from "react";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import "./WinnersPage.scss";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveClassName,
} from "../../utils/responsiveClassHelpers";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Card from "../../components/Card/Card";
import LinkButton from "../../components/LinkButton/LinkButton";
import PrizesList from "../../components/PrizesList/PrizesList";
import TopLeaderCard from "../../components/TopLeaderCard/TopLeaderCard";
import {
  IHackathonTeam,
  IHackathonIndividual,
} from "../../models/participant.model";
import { ELeaderboardOption } from "../../components/Leaderboard/Leaderboard.util";
import { Event } from "../../utils/consts/event";
import { ParticipantContext } from "../../App";

import homeBannerDesktop from "../../assets/images/headers/home/homepage-hero-image.png";
import homeBannerTablet from "../../assets/images/headers/home/homepage-hero-image-tablet.png";
import homeBannerMobile from "../../assets/images/headers/home/homepage-hero-image-mobile.png";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { showWinners } from "../../utils/eventUtil";

const event = Event;

const firstImage = "../../assets/images/Patrick.jpg";
const secondImage = "../../assets/images/Tyrel.jpg";
const thirdImage = "../../assets/images/Daniel.jpg";

const getAboutWelcomeSectionResponsiveClass = (width) =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = (width) =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const url =
  "https://sparkthink.slalom.com/survey/s/134e7c90-a77d-48c0-a091-f3eb6fb8870f";

const prizesList: { title: string; text: string }[] = [
  {
    title: "Top 10 Global",
    text: "The top 10 global winners will receive a Tartana Backpack Cooler!",
  },
  {
    title: "Top 3 Office",
    text: "The top 3 office winners in each office will recieve a Cotopaxi Del Dia Hip Pack!",
  },
  {
    title: "Hackathon",
    text: "Hackathon winners will recieve exclusive prizes from GitGuardian or AWS!",
  },
];

const gitGuardianWinners: IHackathonIndividual[] = [
  {
    firstName: "Patrick",
    lastName: "Ashbrook",
    image: firstImage,
  },
  {
    firstName: "Tyrel",
    lastName: "Fisher",
    image: secondImage,
  },
  {
    firstName: "Daniel",
    lastName: "Evans",
    image: thirdImage,
  },
];

const secureLegendsWinners: IHackathonTeam[] = [
  {
    teamName: "The Invincible",
    teamMembers: [
      "Patrick Ashbrook",
      "Nathaniel Larson",
      "Mike Fink",
      "Ashish Lath",
    ],
  },
  {
    teamName: "TheAwkwardYetis",
    teamMembers: [
      "Constantina Polycarpou",
      "Kirk Larsen",
      "Subheet Sood",
      "Tyrel Fisher",
    ],
  },
];

const WinnersPage: React.FC = () => {
  const { width } = useWindowSize();
  const participant = useContext(ParticipantContext);
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

  const bannerImg =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;

  return (
    <ScrollToTop>
      <PageWrapper bannerImage={bannerImg}>
        <div
          className={`app-page winners-page ${getResponsiveBackgroundClass(
            width
          )}`}
        >
          <div className={getPageContainerClass(width)}>
            <div className={getMainContainerClass(width)}>
              <section
                className={`welcome-section ${getAboutContactSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
              >
                <div id="winner-main-content">
                  <Card
                    containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                      width
                    )}`}
                  >
                    <div className="contact-section-title large">
                      {event.winnersPage.section1Title}
                    </div>
                    <div className="about-page-text-body-container">
                      {event.winnersPage.section1Description}
                      <div className="winners-page-text-body-note">
                        {event.winnersPage.section1DescriptionNote}
                      </div>
                      <div className="winners-page-text-body-note">
                        {event.winnersPage.section1DescriptionNote2}
                        <a
                          href={event.winnersPage.section1DescriptionNote2Link}
                        >
                          here
                        </a>
                      </div>
                    </div>
                  </Card>
                </div>
              </section>
              <section
                className={`welcome-section contact-section ${getAboutContactSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
              >
                <Card
                  containerClass={`contact-card contact-card-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">Got feedback?</div>
                  <div className="contact-section-text-body">
                    {event.aboutPage.aboutEventsFeedbackText}
                  </div>
                  <LinkButton
                    href="mailto: zenith@slalom.com"
                    newTab={true}
                    text="Contact Us"
                  />
                </Card>
                <Card
                  containerClass={`contact-card contact-card-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">
                    Join the Zenith Platform Build Team!
                  </div>
                  <div className="contact-section-text-body">
                    {event.aboutPage.aboutEventsJoinText}
                  </div>
                  <LinkButton href={url} newTab={true} text="Join Us" />
                </Card>
                <Card
                  containerClass={`contact-card contact-card-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">Caught a bug?</div>
                  <div className="contact-section-text-body">
                    {event.aboutPage.aboutEventsBugText}
                  </div>
                  <LinkButton
                    href="mailto: zenith@slalom.com"
                    newTab={true}
                    text="Contact Us"
                  />
                </Card>
              </section>
              <Leaderboard
                isLocalLeaderBoard={false}
                isWinnersLeaderBoard={showWinners}
                participantData={participant}
              />
              <div className="section-container">
                <SectionHeader
                  sectionTitleString={"Secure Legends AWS Gameday"}
                  width={width}
                ></SectionHeader>
                <div className="secure-legends">
                  {secureLegendsWinners.map((winner, idx) => (
                    <TopLeaderCard
                      data={winner}
                      key={`secure-legends-winner-${idx}`}
                      position={idx}
                      type={ELeaderboardOption.Market}
                      hackathonLeaderboard={true}
                    />
                  ))}
                </div>
              </div>
              <div className="section-container">
                <SectionHeader
                  sectionTitleString={"GitGuardian Hunt the Hacker"}
                  width={width}
                ></SectionHeader>
                <div id="gameday-winners">
                  {gitGuardianWinners.map((winner, idx) => (
                    <TopLeaderCard
                      data={winner}
                      key={`gameday-winner-${idx}`}
                      position={idx}
                      type={ELeaderboardOption.Market}
                      hackathonLeaderboard={true}
                      hackathonIndividual={true}
                    />
                  ))}
                </div>
              </div>
              <PrizesList prizes={prizesList} />
            </div>
          </div>
        </div>
      </PageWrapper>
    </ScrollToTop>
  );
};

export default WinnersPage;
