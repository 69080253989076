import styled from "styled-components";

export const Pre = styled.pre`
  text-align: left;
  & .token-line {
    line-height: 1.3em;
    height: 100%;
    width: 100%;
  }
`;

export const Line = styled.div`
  display: table-row;
  height: 100%;
  width: 100%;
`;

export const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

export const LineContent = styled.span`
  display: table-cell;
  width: 100%;
`;
