import React from "react";
import SecuryetiImg from "../../assets/images/securyeti.png";
import "./Securyeti.scss";

function Securyeti() {
  return (
    <div className="securyeti-container">
      <img src={SecuryetiImg} alt="" />
      <p>
        “Congratulations, you found the Securyeti! Use this code: D7T2EW in the
        Events Page to claim your points”
      </p>
    </div>
  );
}

export default Securyeti;
