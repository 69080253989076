import React from "react";
import "./CodeHuntBoard.scss";
import CodeHuntPointsRow from "./CodeHuntPointsRow/CodeHuntPointsRow";

type Props = {
  completed: boolean[];
  available: boolean;
  pointClick: (honeycombId: number, challenge: any) => void;
};

const CodeHuntBoard: React.FC<Props> = ({
  completed,
  available,
  pointClick,
}): React.ReactElement => {
  return (
    <>
      <div className="code-hunt-all-rows">
        <CodeHuntPointsRow
          type={100}
          completed={completed.slice(0, 4)}
          available={available}
          pointClick={pointClick}
        ></CodeHuntPointsRow>
        <CodeHuntPointsRow
          type={200}
          completed={completed.slice(4, 8)}
          available={available}
          pointClick={pointClick}
        ></CodeHuntPointsRow>
        <CodeHuntPointsRow
          type={300}
          completed={completed.slice(8)}
          available={available}
          pointClick={pointClick}
        ></CodeHuntPointsRow>
      </div>

      {/* Unavailable overlay */}
      {!available && (
        <div className="code-hunt-unavailable">
          <strong>Thank you for your efforts.</strong>
          <strong>
            Please come back tomorrow to tackle another code hunt question.
          </strong>
        </div>
      )}
    </>
  );
};

export default CodeHuntBoard;
