import React, { useState } from "react";
import "./CodeHuntQuestionView.scss";
import { useMutation, queryCache } from "react-query";
import { submitCodeHuntAnswer } from "../../../ApiHelper";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import LowLevelChallenge from "./Challenges/LowLevelChallenge";
import MidLevelChallenge from "./Challenges/MidLevelChallenge";
import ChatBot from "../../ChatBot/ChatBot";

type Props = {
  answer: (correct: boolean) => void;
  setHoneycombId: (id: number) => void;
  honeycombId: number;
  challengeData: any;
};

const CodeHuntQuestionView: React.FC<Props> = ({
  answer,
  setHoneycombId,
  honeycombId,
  challengeData,
}): React.ReactElement => {
  const challenge = JSON.parse(challengeData?.challenge);
  const codeHuntAnswers = challenge?.codeHuntAnswers;
  const type = honeycombId < 4 ? 1 : honeycombId < 8 ? 2 : 3;

  // List of selected answer ids
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  // Determines if loading spinner should be shown
  const [loading, setLoading] = useState(false);

  // Backend call mutation
  const [mutate] = useMutation(submitCodeHuntAnswer, {
    onSettled: () => {
      queryCache.invalidateQueries("participant");
      queryCache.invalidateQueries("participants");
    },
  });

  // Will update selected answers
  const toggleAnswer = (answerId) => {
    // Level 100 answers only have one answer selection
    if (type === 1) {
      setSelectedAnswers([answerId]);
    }
    // Level 200 and 300 answers are multi-select
    else {
      setSelectedAnswers((prev) => {
        if (!prev.includes(answerId)) return [...prev, answerId];
        else prev = prev.filter((id) => id !== answerId);
        return prev;
      });
    }
  };

  // Submit selected answer(s)
  const handleSubmit = () => {
    setLoading(true);

    // Submission request data
    const answers = selectedAnswers.join(",");
    const codeHuntSubmitRequest = {
      honeycombId: `hc-${type}0${honeycombId % 4}`,
      challengeId: challenge.challengeId,
      response: answers,
    };

    // Send the post request
    mutate(codeHuntSubmitRequest).then((data: any) => {
      setLoading(false);
      setSelectedAnswers([]);
      setHoneycombId(-1);
      if (data) {
        answer(data.isCorrect);
      }
    });
  };
  return (
    <>
      <LoadingSpinner isLoading={loading} size="big">
        {challengeData && !loading && type === 1 ? (
          <div className="question-view">
            <LowLevelChallenge
              handleChange={toggleAnswer}
              selectedAnswer={selectedAnswers}
              question={challenge?.challenge}
              answers={codeHuntAnswers}
              handleSubmit={handleSubmit}
              handleBack={setHoneycombId}
            />
            <ChatBot />
          </div>
        ) : (
          <div className="question-view">
            <MidLevelChallenge
              handleChange={toggleAnswer}
              selectedAnswer={selectedAnswers}
              question={challenge?.challenge}
              answers={codeHuntAnswers}
              handleSubmit={handleSubmit}
              handleBack={setHoneycombId}
              codeLanguage={challenge?.codeLanguage}
            />
            <ChatBot />
          </div>
        )}
      </LoadingSpinner>
    </>
  );
};

export default CodeHuntQuestionView;
