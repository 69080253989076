import EventsPage from "./EventsPage";
import moment from "moment";

export const workdayURL =
  "https://www.myworkday.com/slalom/d/inst/23455$84/rel-task/2998$35805.htmld ";

export const marketFilterOptions = [
  { key: "all", text: "All", value: "" },
  { key: "canada", text: "All Canada", value: "All Canada" },
  { key: "atlanta", text: "Atlanta", value: "Atlanta" },
  { key: "australia", text: "Australia", value: "Australia" },
  { key: "boston", text: "Boston", value: "Boston" },
  { key: "charlotte", text: "Charlotte", value: "Charlotte" },
  { key: "chicago", text: "Chicago", value: "Chicago" },
  { key: "columbus", text: "Columbus", value: "Columbus" },
  {
    key: "dallas & fort worth",
    text: "Dallas & Fort Worth",
    value: "Dallas & Fort Worth",
  },
  { key: "dc/fed", text: "DC/FED", value: "DC/FED" },
  { key: "detroit", text: "Detroit", value: "Detroit" },
  {
    key: "europe",
    text: "Europe (UK, Germany, Netherlands)",
    value: "Europe (UK, Germany, Netherlands)",
  },
  { key: "florida", text: "Florida", value: "Florida" },
  { key: "hartford", text: "Hartford", value: "Hartford" },
  { key: "houston", text: "Houston", value: "Houston" },
  { key: "minneapolis", text: "Minneapolis", value: "Minneapolis" },
  { key: "new jersey", text: "New Jersey", value: "New Jersey" },
  { key: "new york city", text: "New York City", value: "New York City" },
  { key: "new zealand", text: "New Zealand", value: "New Zealand" },
  { key: "norcal", text: "NorCal", value: "NorCal" },
  { key: "phoenix", text: "Phoenix", value: "Phoenix" },
  { key: "portland", text: "Portland", value: "Portland" },
  { key: "salt lake city", text: "Salt Lake City", value: "Salt Lake City" },
  { key: "seattle", text: "Seattle", value: "Seattle" },
  { key: "socal", text: "SoCal", value: "SoCal" },
  { key: "st. louis", text: "St. Louis", value: "St. Louis" },
  { key: "sydney", text: "Sydney", value: "Sydney" },
  { key: "toronto", text: "Toronto", value: "Toronto" },
  { key: "vancouver", text: "Vancouver", value: "Vancouver" },
];

export const dayFilterOptions = [
  { key: "all", text: "All", value: "0" },
  { key: "monday", text: "Monday", value: "1" },
  { key: "tuesday", text: "Tuesday", value: "2" },
  { key: "wednesday", text: "Wednesday", value: "3" },
  { key: "thursday", text: "Thursday", value: "4" },
  { key: "friday", text: "Friday", value: "5" },
];

export const timeFilterOptions = [
  { key: "all", text: "All", value: "all" },
  { key: "morning", text: "Morning", value: "morning" },
  { key: "afternoon", text: "Afternoon", value: "afternoon" },
];

export const topicFilterOptions = [{ key: "all", text: "All", value: "" }];

const stripDate = (momentStr) =>
  moment(moment(momentStr).format("LT"), "HH:mm A");

const isMorning = (momentStr) =>
  stripDate(momentStr).isBefore(moment("12:00", "HH:mm A"));

// Regex here is looking for " & " or " and " in case there is more than one market for the event.
const formatMarket = (market) => market.split(/\s+&\s+|\s+\band\b\s+/);

export const filterTopicEvents = (event, selectedTopics) =>
  selectedTopics.includes("all") || !selectedTopics.length
    ? true
    : "categoryName" in event &&
      selectedTopics.includes(event.categoryName.toString());

export const filterMarketEvents = (event, selectedMarkets) =>
  selectedMarkets.includes("") || !selectedMarkets.length
    ? true
    : formatMarket(event.hostMarket.toString()).some((market) =>
        selectedMarkets.includes(market)
      );

export const filterDayEvents = (event, selectedDays) =>
  selectedDays.includes("0") || !selectedDays.length
    ? true
    : selectedDays.includes(moment(event.startDateTime).day().toString());

export const filterTimeEvents = (event, selectedTimes) => {
  if (
    selectedTimes.includes("all") ||
    !selectedTimes.length ||
    (selectedTimes.includes("morning") && selectedTimes.includes("afternoon"))
  ) {
    return true;
  }
  if (selectedTimes.includes("morning")) {
    return isMorning(event.startDateTime) || isMorning(event.endDateTime);
  }
  if (selectedTimes.includes("afternoon")) {
    return !isMorning(event.startDateTime) || !isMorning(event.endDateTime);
  }
};

let selectedFilters = (
  selectedTopics,
  selectedMarkets,
  selectedDays,
  selectedTimes
) => ({
  days: selectedDays,
  times: selectedTimes,
  markets: selectedMarkets,
  topics: selectedTopics,
});

export const filteredEvents = (
  eventsData,
  selectedTopics,
  selectedMarkets,
  selectedDays,
  selectedTimes
) =>
  eventsData?.filter
    ? eventsData
        .filter((event) => {
          const filterKeys = Object.keys(
            selectedFilters(
              selectedTopics,
              selectedMarkets,
              selectedDays,
              selectedTimes
            )
          );
          return filterKeys.every((key) =>
            key === "days"
              ? filterDayEvents(event, selectedDays)
              : key === "times"
              ? filterTimeEvents(event, selectedTimes)
              : key === "markets"
              ? filterMarketEvents(event, selectedMarkets)
              : filterTopicEvents(event, selectedTopics)
          );
        })
        .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)))
    : [];

export const mockEventsData = [
  {
    startDateTime: "2021-10-05T14:00:00Z",
    endDateTime: "2021-10-05T14:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-05T16:00:00Z",
    endDateTime: "2021-10-05T17:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-05T18:00:00Z",
    endDateTime: "2021-10-05T18:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-06T14:00:00Z",
    endDateTime: "2021-10-06T14:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-06T18:00:00Z",
    endDateTime: "2021-10-06T18:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-07T14:00:00Z",
    endDateTime: "2021-10-07T14:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-07T18:00:00Z",
    endDateTime: "2021-10-07T18:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-08T14:00:00Z",
    endDateTime: "2021-10-08T14:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-08T18:00:00Z",
    endDateTime: "2021-10-08T18:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-09T14:00:00Z",
    endDateTime: "2021-10-09T14:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
  {
    startDateTime: "2021-10-09T18:00:00Z",
    endDateTime: "2021-10-09T18:30:00Z",
    title: "CHI: AWS Serverless Security Workshop",
    points: 10,
    content:
      "Here is a preview of the event details to give the users an introduction/some idea as to what they can expect from this event to help them make a decision on whether they want to attend or view later. This will be a lead-in text that is complete. \n Zombie ipsum reversus ab viral inferno, nam rick grimes malum cerebro. De carne lumbering animata corpora quaeritis. Summus brains sit​​, morbo vel maleficia? De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium.",
    market: "Global (Skype meeting enabled) Chicago",
    signupLink: "www.google.com",
    speakers: [
      {
        firstName: "Azhar",
        lastName: "Ahmad",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Zhi-Mei",
        lastName: "Li",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
      {
        firstName: "Phaulo",
        lastName: "Escalante",
        image: "https://www.w3schools.com/howto/img_avatar2.png",
        user: `${Math.random(100)}@slalom.com`,
        market: "Chicago",
      },
    ],
  },
];

export default EventsPage;
