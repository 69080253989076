import React from "react";
import "./NavLogo.scss";
import { NavLink } from "react-router-dom";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

type NavLogoProps = {
  width: number;
  wrap?: boolean;
};

const NavLogo: React.FC<NavLogoProps> = ({ width, wrap }: NavLogoProps) => {
  return (
    <NavLink
      to="/"
      className={`nav-logo-${getResponsiveClassName(width)} ${
        wrap && "top-left"
      }`}
    >
      <img
        src={require("../../assets/images/hacktober-23-navbar-logo.svg")}
        alt="logo"
      />
    </NavLink>
  );
};

export default NavLogo;
