import React from "react";
import PropTypes from "prop-types";
import ProfileCardList from "../ProfileCardList/ProfileCardList";
import "./EventCard.scss";
import { useQuery } from "react-query";
import { getEventDetail } from "../../ApiHelper";
import {
  startTimeStr,
  endTimeStr,
  timezoneStr,
  dateStr,
  shortenContent,
  extendContent,
} from ".";
import LoadingSpinner from "../LoadingSpinner";
import { useState } from "react";
import Card from "../Card/Card";
import Button from "../Button/Button";
import { EButtonStyle } from "../../utils/buttonUtils";
import LinkButton from "../LinkButton/LinkButton";
import outlinedIcon from "../../assets/images/outlined-icon.png";
import KudosButton from "../KudosButton/KudosButton";
const showButtons = true;

const renderEventCard = ({
  startDateTime,
  endDateTime,
  title,
  points,
  content,
  hostMarket,
}) => (
  <div className="event-details">
    <div className={"event-card-title"}>{title}</div>
    <div className="event-points-container">
      <img src={outlinedIcon} alt="points"></img>
      <div className="event-points">{points} points</div>
    </div>
    <div className="event-date">
      {`${startDateTime && dateStr(startDateTime)} at `}
      {startDateTime && startTimeStr(startDateTime)} -{" "}
      {endDateTime && endTimeStr(endDateTime)} {timezoneStr}
    </div>
    <p className="event-content">{shortenContent(content)}</p>
    {/* <p className="event-location">
      <b>Market: </b> {hostMarket}
    </p> */}
  </div>
);

const EventActionButton = ({
  url,
  isPastEvent,
  liked = false,
  eventId,
  activityLikeId,
}: {
  url: string;
  isPastEvent: boolean;
  liked: boolean;
  eventId: number;
  activityLikeId: number;
}) => {
  // Buttons that can show up on an event card include:
  // * "Add to Calendar"
  // * "Learn More"
  // * "GIVE KUDOS" (only for past events)

  const linkButtonTitle = url.includes(".ics")
    ? "Add to Calendar"
    : "Learn More";

  return (
    <div className="event-card-primary-btn-wrapper">
      {isPastEvent ? (
        <KudosButton
          giveKudos={liked}
          eventId={eventId}
          activityLikeId={activityLikeId}
        />
      ) : (
        <LinkButton newTab={true} href={url} text={linkButtonTitle} />
      )}
    </div>
  );
};

const EventDetails = ({ eventId, details }) => {
  const {
    data: eventDetailData,
    error: eventDetailError,
    isLoading: eventDetailIsLoading,
  } = useQuery(["eventDetail", eventId], getEventDetail, {
    enabled: eventId,
  });
  let content = "";
  let speakerProfiles = [];
  if (!eventDetailIsLoading) {
    content = eventDetailData?.content;
    speakerProfiles = eventDetailData?.speakerProfiles;
  }
  return (
    <LoadingSpinner
      isLoading={eventDetailError != null || eventDetailData === undefined}
      error={eventDetailIsLoading}
      size="massive"
    >
      <div className="event-details-expanded">
        {extendContent(content, details)}
      </div>
      {speakerProfiles && (
        <div style={{ marginBottom: "30px" }}>
          <ProfileCardList participants={speakerProfiles} />
        </div>
      )}
    </LoadingSpinner>
  );
};

const renderEventCardOrDetails = (displayDetails, eventCardProps) => {
  return !displayDetails ? (
    renderEventCard(eventCardProps)
  ) : (
    <EventDetails eventId={eventCardProps?.eventId} details={displayDetails} />
  );
};

const EventCard = (props) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  return (
    <Card
      containerClass={`event-card-container ${displayDetails ? "flipped" : ""}`}
      contentStyle={{
        height: "650px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {renderEventCardOrDetails(displayDetails, props)}

      <div>
        {
          <EventActionButton
            url={props.signupLink}
            isPastEvent={props.isPastEvent}
            liked={props.isLiked}
            eventId={props.eventId}
            activityLikeId={props.activityLikeId}
          />
        }
        {showButtons && (
          <Button
            style={EButtonStyle.SECONDARY}
            type="button"
            onClick={() => {
              setDisplayDetails(!displayDetails);
            }}
            value={!displayDetails ? "Read more ›" : `Back ›`}
            classes={["button-text"]}
          />
        )}
      </div>
    </Card>
  );
};

EventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
  signupLink: PropTypes.string,
  detail: PropTypes.bool,
  speakers: PropTypes.array,
  eventId: PropTypes.number,
  activityLikeId: PropTypes.number,
  isPastEvent: PropTypes.bool,
  isLiked: PropTypes.bool,
};

EventDetails.propTypes = {
  eventId: PropTypes.number,
  details: PropTypes.bool,
};

renderEventCard.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
  title: PropTypes.string,
  points: PropTypes.number,
  content: PropTypes.string,
};

export default EventCard;
