import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import InfoIcon from "../../assets/images/hacktober-23-tooltip.svg";
import "./Tooltip.scss";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";

const { TABLET_MAX } = deviceWidthBreakpoints;
const Tooltip = ({ children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { width } = useWindowSize();
  const tooltipContentRef = useRef(null);
  useDetectOutsideClick(tooltipContentRef, () => setTooltipVisible(false));

  return (
    <div className="tooltip-container">
      <div className="tooltip-icon">
        <img
          className="tooltip-icon__circle"
          onClick={() => setTooltipVisible(true)}
          src={InfoIcon}
          width={width <= TABLET_MAX ? 25 : 35}
          height={width <= TABLET_MAX ? 25 : 35}
          alt="tooltip icon"
        />
      </div>
      {tooltipVisible && (
        <div ref={tooltipContentRef} className="tooltip-content">
          <div className="tooltip-close">
            <p onClick={() => setTooltipVisible(false)}>X</p>
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.any,
};

export default Tooltip;
