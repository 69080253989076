import React from "react";
import "./CodeHuntPointsRow.scss";
import CodeHuntPoint from "../CodeHuntPoint/CodeHuntPoint";

type Props = {
  type: number;
  completed: any[];
  available: boolean;
  pointClick: (honeycombId: number, challenge: any) => void;
};

const CodeHuntPointsRow: React.FC<Props> = ({
  type,
  completed,
  available,
  pointClick,
}): React.ReactElement => {
  const ids: number[] =
    type === 100 ? [0, 1, 2, 3] : type === 200 ? [4, 5, 6, 7] : [8, 9, 10, 11];

  return (
    <div className={`code-hunt-points-row ${type === 200 ? "middle-row" : ""}`}>
      <CodeHuntPoint
        honeycombId={ids[0]}
        type={type}
        completed={completed[0]}
        available={available}
        pointClick={pointClick}
      ></CodeHuntPoint>
      <CodeHuntPoint
        honeycombId={ids[1]}
        type={type}
        completed={completed[1]}
        available={available}
        pointClick={pointClick}
      ></CodeHuntPoint>
      <CodeHuntPoint
        honeycombId={ids[2]}
        type={type}
        completed={completed[2]}
        available={available}
        pointClick={pointClick}
      ></CodeHuntPoint>
      <CodeHuntPoint
        honeycombId={ids[3]}
        type={type}
        completed={completed[3]}
        available={available}
        pointClick={pointClick}
      ></CodeHuntPoint>
    </div>
  );
};

export default CodeHuntPointsRow;
