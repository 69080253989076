import React from "react";
import {
  IHackathonIndividual,
  IHackathonTeam,
  IMarket,
  IParticipant,
} from "../../models/participant.model";
import "./TopLeaderCard.scss";
import { ELeaderboardOption } from "../Leaderboard/Leaderboard.util";
import { getDataImage } from "../../utils/imageUtils";
import PointsCard from "../../components/PointsCard/PointsCard";
import useWindowSize from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";

type LeaderCardProps = {
  type: ELeaderboardOption;
  data: IParticipant | IMarket | IHackathonTeam | IHackathonIndividual;
  position: number;
  donationsLeaderboard?: boolean;
  hackathonLeaderboard?: boolean;
  hackathonIndividual?: boolean;
};

const firstPlaceImage = require("../../assets/images/Patrick.jpg");
const secondPlaceImage = require("../../assets/images/Tyrel.jpg");
const thirdPlaceImage = require("../../assets/images/Daniel.jpg");

const images = [firstPlaceImage, secondPlaceImage, thirdPlaceImage];

const TopLeaderCard: React.FC<LeaderCardProps> = ({
  type,
  data,
  position,
  donationsLeaderboard,
  hackathonLeaderboard,
  hackathonIndividual,
}): React.ReactElement => {
  const { width } = useWindowSize();
  const isUserParticipant =
    type === ELeaderboardOption.Global || type === ELeaderboardOption.Local;
  return (
    <div className={`top-leader-card top-leader-card--${position}`}>
      <div
        className={`top-leader-content-${getResponsiveClassName(
          width
        )} top-leader-shadow`}
      >
        {isUserParticipant ? (
          <>
            <h2>#{position + 1}</h2>
            <img
              className="top-leader-avatar"
              src={getDataImage((data as IParticipant).image)}
              alt="avatar"
            />
            <h2 className="top-leader-stat-h2">
              {(data as IParticipant).firstName}
            </h2>
            <h2 className="top-leader-stat-h2">
              {(data as IParticipant).lastName}
            </h2>
            <h3 className="top-leader-stat-h3">
              {(data as IParticipant).market}
            </h3>
            <h3 className="top-leader-stat-h4">
              <PointsCard
                text={(data as IParticipant).totalPoints + " Points"}
              />
            </h3>
          </>
        ) : donationsLeaderboard ? (
          <>
            <h2>#{position + 1}</h2>
            <h3 className="top-leader-stat-h2">{(data as IMarket).name}</h3>
            <h3 className="top-leader-stat-h3">
              <PointsCard text={"$" + (data as IMarket).totalDonations} />
            </h3>
          </>
        ) : hackathonLeaderboard ? (
          <>
            <h2>#{position + 1}</h2>
            {hackathonIndividual ? (
              <>
                <img
                  className="top-leader-avatar"
                  src={images[position]}
                  alt="avatar"
                />
                <h2 className="top-leader-stat-h2">
                  {(data as IHackathonIndividual).firstName}
                </h2>
                <h2 className="top-leader-stat-h2">
                  {(data as IHackathonIndividual).lastName}
                </h2>
              </>
            ) : (
              <>
                <h2 className="top-leader-stat-h2">
                  {(data as IHackathonTeam).teamName}
                </h2>
                <h3 className="top-leader-stat-h4">
                  {(data as IHackathonTeam).teamMembers.map((member, idx) => (
                    <PointsCard key={idx} text={member} />
                  ))}
                </h3>
              </>
            )}
          </>
        ) : (
          <>
            <h3 className="top-leader-stat-h2">{(data as IMarket).name}</h3>
            <h3 className="top-leader-stat-h3">
              <PointsCard
                text={(data as IParticipant).totalPoints + " Points"}
              />
            </h3>
          </>
        )}
      </div>
      <div className={`position-image position-${position}`}></div>
    </div>
  );
};

export default TopLeaderCard;
