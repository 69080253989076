const currDateTime = new Date().getTime();
const startDateTime = new Date(2023, 9, 1, 0).getTime();
// endDateTime should be set to the day after the actual event end date.
const endDateTime = new Date(2023, 10, 1, 0).getTime();
const winnersAnnouncedDateTime = new Date(2023, 10, 10, 0).getTime();

export const isBeforeEventStart = currDateTime < startDateTime;

export const eventHasEnded = currDateTime >= endDateTime;
export const showWinners = currDateTime >= winnersAnnouncedDateTime;

export const showEndAnnouncements = eventHasEnded && !showWinners;

export const showFullSite =
  currDateTime >= startDateTime && currDateTime < endDateTime;
