import React, { FC } from "react";
import "./SectionHeader.scss";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import Tooltip from "../Tooltip/Tooltip";

type SectionHeaderProps = {
  sectionTitleString: string;
  width: number;
  tooltipContent?: any;
};

// Component for website's section headers Hacktober 2023
// Pass props for section title, width, and optional tooltip
const SectionHeader: FC<SectionHeaderProps> = ({
  sectionTitleString,
  width,
  tooltipContent,
}: SectionHeaderProps): JSX.Element => {
  const sizeName = getResponsiveClassName(width);
  return (
    <div className="section-header">
      <div className={`section-title ${sizeName}`}>
        <div className={`section-header-circle ${sizeName}`}></div>
        <div className={`section-header-icon ${sizeName}`}></div>
        {sectionTitleString}
        <div className={`section-header-icon flip ${sizeName}`}></div>
        <div className={`section-header-circle ${sizeName}`}></div>
      </div>
      {tooltipContent ? <Tooltip>{tooltipContent}</Tooltip> : <></>}
    </div>
  );
};

export default SectionHeader;
