import React, { useContext, useEffect, useState } from "react";
import "./TopNavigationBar.scss";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import { eventHasEnded } from "../../utils/eventUtil";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import NavLogo from "../NavLogo/NavLogo";
import NavUserAndPoints from "./NavUserAndPoints/NavUserAndPoints";
import NavOptions from "./NavOptions/NavOptions";
import NavHamburgerMenu from "./NavHamurgerMenu/NavHamburgerMenu";
import { ParticipantContext } from "../../App";

const { TABLET_MAX } = deviceWidthBreakpoints;

export enum ENavLink {
  Winners = "Winners",
  Events = "Events",
  Home = "Home",
  About = "About",
  Faqs = "FAQs",
  Hacker = "Hacker",
}

const getNavLinks = () => {
  return eventHasEnded
    ? [ENavLink.Home, ENavLink.About]
    : [
        ENavLink.Home,
        ENavLink.Hacker,
        ENavLink.About,
        ENavLink.Events,
        ENavLink.Faqs,
      ];
};

const TopNavigationBar: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const navLinks = getNavLinks();
  const [showModal, setShowModal] = useState(false);
  const participant = useContext(ParticipantContext);

  useEffect(() => {
    if (participant?.firstVisit) {
      setShowModal(true);
    }
  }, [participant]);

  return (
    <header id="12">
      <nav
        className={`navigation-bar-container-${getResponsiveClassName(width)}`}
      >
        <NavLogo width={width} />
        {width > TABLET_MAX && <NavOptions />}
        {width <= TABLET_MAX && (
          <NavHamburgerMenu
            width={width}
            participantData={participant}
            navLinks={navLinks}
          />
        )}
        <NavUserAndPoints
          width={width}
          participantData={participant}
          participantIsLoading={!participant}
        />
      </nav>
      <WelcomeModal show={showModal} setShow={setShowModal} />
    </header>
  );
};

export default TopNavigationBar;
