import React, { useState } from "react";
import "./KudosButton.scss";
import Button from "../Button/Button";
import KudosIcon from "../../assets/images/hacktober-23-kudos-icon.svg";
import { postLikeEvent } from "../../ApiHelper";
import { useMutation } from "react-query";
import { EButtonStyle } from "../../utils/buttonUtils";

type Props = {
  giveKudos: boolean;
  eventId: number;
  activityLikeId: number;
};

const KudosButton: React.FC<Props> = ({
  giveKudos,
  eventId,
  activityLikeId,
}): React.ReactElement => {
  const [showKudosSubmitted, setShowKudosSubmitted] = useState(giveKudos);
  const [shouldButtonBeDisabled, setShouldButtonBeDisabled] = useState(false);

  const [mutate] = useMutation(postLikeEvent, {
    onSuccess: (data) => {
      setShowKudosSubmitted(data.liked); // update state based on response
      setShouldButtonBeDisabled(false); // re-enable button
    },
  });

  const submitKudos = () => {
    setShouldButtonBeDisabled(true);
    mutate({
      eventId,
      activityLikeId,
      liked: !showKudosSubmitted, // toggle liked status
    });
  };

  const buttonClassList: string[] = ["kudos-button"];

  if (showKudosSubmitted) {
    buttonClassList.push("kudos-button--submitted");
  }

  const buttonValue = showKudosSubmitted ? "KUDOS!" : "GIVE KUDOS";
  const iconSrc = showKudosSubmitted ? KudosIcon : undefined;

  return (
    <Button
      type="submit"
      disabled={shouldButtonBeDisabled}
      onClick={submitKudos}
      value={buttonValue}
      classes={buttonClassList}
      iconSrc={iconSrc}
      style={showKudosSubmitted ? EButtonStyle.PRIMARY : EButtonStyle.SECONDARY}
    />
  );
};

export default KudosButton;
