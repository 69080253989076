import React, { FC } from "react";
import "./HackathonSection.scss";
import useWindowSize from "../../../utils/useWindowSize";
import { getResponsiveClassName } from "../../../utils/responsiveClassHelpers";
import HackathonInfoTile from "../HackathonInfoTile/HackathonInfoTile";
import SectionHeader from "../../SectionHeader/SectionHeader";

type Props = {
  title: string;
  aboutContent: any;
  aboutUrl?: string;
  howToParticipateContent: any;
  howToParticipateUrl?: string;
  signUpContent: any;
  signUpUrl?: string;
};

const HackathonSection: FC<Props> = ({
  title,
  aboutContent,
  aboutUrl,
  howToParticipateContent,
  howToParticipateUrl,
  signUpContent,
  signUpUrl,
}) => {
  const { width } = useWindowSize();

  return (
    <>
      <SectionHeader sectionTitleString={title} width={width}></SectionHeader>
      <section
        className={`hackathon welcome-section welcome-section-card contact-section-container-${getResponsiveClassName(
          width
        )} welcome-section-${getResponsiveClassName(width)}`}
      >
        <HackathonInfoTile
          cardTitle="About this Hackathon"
          content={aboutContent}
          url={aboutUrl}
          buttonText="Learn More"
        ></HackathonInfoTile>
        <HackathonInfoTile
          cardTitle="How to Participate"
          content={howToParticipateContent}
          url={howToParticipateUrl}
          buttonText="Read More"
        ></HackathonInfoTile>
        <HackathonInfoTile
          cardTitle="Sign Up"
          content={signUpContent}
          url={signUpUrl}
          buttonText="Sign up"
        ></HackathonInfoTile>
      </section>
    </>
  );
};

export default HackathonSection;
