import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";
import { apiBaseUrl } from "./config";

const AUTH_PROVIDER = "SlalomOIDC";
const LOCAL_USER_DATA = "user-data";
const API_BASE_URL = apiBaseUrl;
const EVENT_TYPE = "hacktober";

const extractUserEmail = (idToken) => {
  const idTokenData = jwtDecode(String(idToken));
  return idTokenData?.email;
};

export const getHeaders = (userData) => {
  return {
    headers: {
      Authorization: "Bearer " + userData?.jwtToken,
      "Event-Type": EVENT_TYPE,
    },
  };
};

export const getHeadersPOST = (userData, mutatedData) => {
  return {
    method: "POST",
    headers: {
      Authorization: "Bearer " + userData?.jwtToken,
      "Event-Type": EVENT_TYPE,
    },
    body: JSON.stringify(mutatedData),
  };
};

export const refreshToken = async () => {
  let authResponse;
  try {
    // Make sure we have a current access token
    authResponse = await Auth.currentSession();
  } catch (e) {
    await Auth.federatedSignIn({ customProvider: AUTH_PROVIDER });
    authResponse = await Auth.currentSession();
  }
  const userData = {
    jwtToken: authResponse?.idToken?.jwtToken,
    userId: authResponse?.idToken?.payload?.identities[0]?.userId,
    exp: authResponse?.idToken?.payload?.exp,
  };

  // Push the refreshed token into the "user-data" localstorage, which is referenced by some components
  localStorage.setItem(LOCAL_USER_DATA, JSON.stringify(userData));
  return userData;
};

export const getParticipant = async () => {
  const userData = await refreshToken();
  const email = extractUserEmail(userData.jwtToken);
  return await fetch(
    `${API_BASE_URL}/participant/${email}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getMarkets = async () => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/participants/markets`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getParticipants = async (key, market) => {
  const userData = await refreshToken();
  if (market) {
    return await fetch(
      `${API_BASE_URL}/participants/${market}`,
      getHeaders(userData)
    ).then((res) => (res.status !== 500 ? res.json() : null));
  } else {
    return null;
  }
};

export const getEvents = async () => {
  const userData = await refreshToken();
  return await fetch(`${API_BASE_URL}/events`, getHeaders(userData)).then(
    (res) => (res.status !== 500 ? res.json() : null)
  );
};

export const getEventDetail = async (key, eventId) => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/events/${eventId}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getWeeklyCodeHunt = async () => {
  const userData = await refreshToken();
  const email = extractUserEmail(userData.jwtToken);
  return await fetch(
    `${API_BASE_URL}/codehunt/${email}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const postWeeklyCodeHunt = async (mutatedData) => {
  const userData = await refreshToken();
  const saveCodeHuntRequest = {
    answers: mutatedData,
    user: extractUserEmail(userData.jwtToken),
  };
  return await fetch(
    `${API_BASE_URL}/codehunt/answers`,
    getHeadersPOST(userData, saveCodeHuntRequest)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const postEventClaim = async (mutatedData) => {
  const userData = await refreshToken();
  let claimRequest = {
    eventCode: mutatedData,
    user: extractUserEmail(userData.jwtToken),
  };
  return await fetch(
    `${API_BASE_URL}/events/claim`,
    getHeadersPOST(userData, claimRequest)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};
export const getQuiz = async (key) => {
  const userData = await refreshToken();
  const email = extractUserEmail(userData.jwtToken);
  return await fetch(
    `${API_BASE_URL}/quiz/${email}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const postQuizAnswer = async (mutatedData) => {
  const userData = await refreshToken();
  let quizRequest = {
    ...mutatedData,
    user: extractUserEmail(userData.jwtToken),
  };
  return await fetch(
    `${API_BASE_URL}/quiz/answer`,
    getHeadersPOST(userData, quizRequest)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const postLikeEvent = async ({ eventId, activityLikeId, liked }) => {
  const userData = await refreshToken();
  const likeEventData = {
    userId: extractUserEmail(userData.jwtToken),
    activityLikeId,
    liked,
  };
  return await fetch(
    `${API_BASE_URL}/event/${eventId}/like`,
    getHeadersPOST(userData, likeEventData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getSpotTheFake = async () => {
  const userData = await refreshToken();
  const email = extractUserEmail(userData.jwtToken);
  return await fetch(
    `${API_BASE_URL}/spotthefake/${email}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const saveSpotTheFake = async (mutatedData) => {
  const userData = await refreshToken();
  let saveSpotTheFakeRequest = {
    ...mutatedData,
    user: extractUserEmail(userData.jwtToken),
  };
  return await fetch(
    `${API_BASE_URL}/spotthefake`,
    getHeadersPOST(userData, saveSpotTheFakeRequest)
  );
};

export const getHintMessage = async (message) => {
  const userData = await refreshToken();
  const body = {
    message: message,
  };

  return await fetch(
    `${API_BASE_URL}/codehunt/hint`,
    getHeadersPOST(userData, body)
  ).then((res) => res.status !== 500 && res.json());
};

// Code hunt
export const getUserBoard = async () => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/codehunt/userBoard`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getCodeHuntChallengeById = async (key, id) => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/codehunt/challenges/id/${id}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getCodeHuntChallengeByLevel = async (key, level) => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/codehunt/challenges/level/${level}`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const getCodeHuntChallenges = async () => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/codehunt/challenges`,
    getHeaders(userData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};

export const submitCodeHuntAnswer = async (mutatedData) => {
  const userData = await refreshToken();
  return await fetch(
    `${API_BASE_URL}/codehunt/answers`,
    getHeadersPOST(userData, mutatedData)
  ).then((res) => (res.status !== 500 ? res.json() : null));
};
