import React from "react";
import "./TeamInfoTile.scss";
import { AboutInfoItem } from "../../utils/interfaces/AboutInfoItem.interface";
import Card from "../Card/Card";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import useWindowSize from "../../utils/useWindowSize";

interface TeamInfoTileProps {
  participant: AboutInfoItem;
  allowFlipTile: boolean;
}
const TeamInfoTile = ({ participant }: TeamInfoTileProps) => {
  const renderProfileTile = ({
    fullName,
    role,
    market,
    pronouns,
  }: AboutInfoItem): JSX.Element => {
    return (
      <div className="team-profile-front-tile-info-container">
        <div className="team-profile-name-container">{`${fullName}`}</div>
        <div className="team-profile-role-container">{`${role}${
          market ? " | " + market : ""
        }`}</div>
        <div className="team-profile-role-container">{`${pronouns}`}</div>
      </div>
    );
  };

  const { professionalPhoto } = participant;
  const { width } = useWindowSize();

  return (
    <div
      className="team-profile-tile-container profile-tile-margin"
      data-testid="team-profile-front-tile-container"
    >
      <Card>
        <div className="about-info-container">
          <div
            className={`team-profile-front-tile-image-container ${getResponsiveClassName(
              width
            )}`}
            style={{
              backgroundImage: `url(${professionalPhoto})`,
            }}
          />
          {renderProfileTile(participant)}
        </div>
      </Card>
    </div>
  );
};

export default TeamInfoTile;
