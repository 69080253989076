import React from "react";
import "./ChatAvatar.scss";

type ChatAvatarProps = {
  onClick: (value: boolean) => void;
  visible: boolean;
};

const ChatAvatar: React.FC<ChatAvatarProps> = ({
  onClick,
  visible,
}): React.ReactElement => {
  return !visible ? (
    <img
      src={require("../../../assets/images/Clipyeti.png")}
      className="chatbot-clickable-avatar"
      alt="secureyeti"
      onClick={() => onClick(visible)}
    />
  ) : (
    <img
      src={require("../../../assets/images/Clipyeti-no-help.png")}
      className="chatbot-clickable-avatar-no-help"
      alt="secureyeti"
      onClick={() => onClick(visible)}
    />
  );
};
export default ChatAvatar;
