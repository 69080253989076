import React, { FC } from "react";
import PointsCard from "../../../components/PointsCard/PointsCard";
import { getDataImage } from "../../../utils/imageUtils";

type LeaderListingProps = {
  rank: Number;
  image: any;
  firstName: string;
  lastName: string;
  name: string;
  market: string;
  totalPoints: Number;
  selectedOption: "Global" | "Market" | "Local";
  lastRow?: boolean;
};

const LeaderListing: FC<LeaderListingProps> = ({
  rank,
  image,
  firstName,
  lastName,
  name,
  market,
  totalPoints,
  selectedOption,
  lastRow = false,
}: LeaderListingProps) => {
  return (
    <div
      className={`leader-listing ${
        lastRow ? "leader-listing:last-child" : ""
      } ${selectedOption === "Market" && "leader-listing-market"}`}
    >
      <div className="listing-rank listing-text-style">{rank}</div>
      <div className="listing-participant ">
        {selectedOption !== "Market" && (
          <img
            src={getDataImage(image)}
            className="listing-avatar"
            alt="avatar"
          />
        )}
        <div className="listing-text-style">{`${
          name || `${firstName} ${lastName}`
        }`}</div>
      </div>
      <div>{market}</div>
      <PointsCard text={totalPoints + " Points"} />
    </div>
  );
};

export default LeaderListing;
