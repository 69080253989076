import React, { useEffect, useState } from "react";
import "./CodeHunt.scss";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import databaseWhite from "../../assets/images/database-white.svg";
import CodeHuntBottomCog from "../../assets/images/hacktober-23-hackathon-bottom-left-cog.png";
import CodeHuntTopCog from "../../assets/images/hacktober-23-hackathon-top-right-cog.png";
import successAnimation from "../../assets/videos/hacktober_CodeHunt_animation_success.mp4";
import failAnimation from "../../assets/videos/hacktober_CodeHunt_animation_fail.mp4";
import Card from "../Card/Card";
import CodeHuntQuestionView from "./CodeHuntQuestionView/CodeHuntQuestionView";
import { getUserBoard } from "../../ApiHelper";
import { queryCache, useQuery } from "react-query";
import CodeHuntBoard from "./CodeHuntBoard/CodeHuntBoard";
import SectionHeader from "../SectionHeader/SectionHeader";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const CodeHunt: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

  // Get user board
  const { data: userBoardData, isLoading: userBoardLoading } = useQuery<any>(
    ["userBoard"],
    getUserBoard
  );

  // Translate honeycomb id string to index in completed array
  const translateHoneycombId = (id: string) => {
    const base = id.startsWith("hc-1") ? 0 : id.startsWith("hc-2") ? 4 : 8;
    return base + parseInt(id.charAt(id.length - 1));
  };

  // Update completed array and available has loaded
  useEffect(() => {
    const answers =
      userBoardLoading ||
      userBoardData === null ||
      Array.isArray(userBoardData?.userBoard)
        ? []
        : JSON.parse(userBoardData.userBoard);
    setCompleted(() => {
      const correctAnswers = answers.filter((a) => a.isCorrect);
      let completed = Array(12).fill(false);
      correctAnswers.forEach((a) => {
        const index = translateHoneycombId(a.honeycombId);
        if (index >= 0 && index < 12) completed[index] = true;
      });
      return completed;
    });
    setAvailable(() => {
      const now = new Date();
      const lastAnsweredDate = userBoardLoading
        ? now
        : new Date(
            Math.max(...answers.map((e) => new Date(e.responseDateTime)))
          );
      const nextQuestionAvailableDate =
        userBoardLoading || isNaN(lastAnsweredDate?.getTime())
          ? now
          : new Date(
              lastAnsweredDate.getUTCFullYear(),
              lastAnsweredDate.getUTCMonth(),
              lastAnsweredDate.getDate(),
              23,
              59,
              59
            );
      return now >= nextQuestionAvailableDate;
    });
  }, [userBoardLoading, userBoardData]);

  // Determines whether challenges have been completed
  const [completed, setCompleted] = useState(Array(12).fill(false));

  // Determines whether CodeHunt is available for the day
  const [available, setAvailable] = useState(true);

  // The index honeycomb clicked that is currently on screen or -1 if not on a question
  const [honeycombId, setHoneycombId] = useState(-1);

  // Current challenge question being asked
  const [challengeData, setChallengeData] = useState(null);

  // Determines if the selected answer is correct or not
  const [correctAnswer, setCorrectAnswer] = useState(true);

  // Determines whether an animation is being shown
  const [showingAnimation, setShowingAnimation] = useState(false);

  // When a point is clicked, show the question
  const pointClick: (honeycombIdNumber: number, challenge: any) => void = (
    honeycombIdNumber: number,
    challenge: any
  ) => {
    if (available && !completed[honeycombIdNumber]) {
      setChallengeData(challenge);
      setHoneycombId(honeycombIdNumber);
    }
  };

  // When an answer is chosen, call backend and show animation
  const answer: (correct: boolean) => void = (correct: boolean) => {
    // Prevent answers when unavailable or already complete
    if (!available || completed[honeycombId]) {
      return;
    }

    // Prevent more questions from being answered for the day (BE also handles this)
    setAvailable(false);

    // Set question to completed if correct
    if (correct) {
      setCompleted((prevCompleted) => {
        prevCompleted[honeycombId] = true;
        return prevCompleted;
      });
    }

    // Go back to board view
    setHoneycombId(-1);

    // Play corresponding animation
    setCorrectAnswer(correct);
    setShowingAnimation(true);
  };

  // Render the background cogs
  const renderBg = () => {
    return width > TABLET_MAX ? (
      <>
        <img
          src={CodeHuntBottomCog}
          alt="code-hunt-bg"
          className="code-hunt-bg-cog bottom-left"
        ></img>
        <img
          src={CodeHuntTopCog}
          alt="code-hunt-bg"
          className="code-hunt-bg-cog top-right"
        ></img>
      </>
    ) : width > MOBILE_MAX ? (
      <img
        src={CodeHuntTopCog}
        alt="code-hunt-bg"
        className="code-hunt-bg-cog top-right"
      ></img>
    ) : null;
  };

  return (
    <>
      {/* Header */}
      <SectionHeader
        sectionTitleString="Digital Code Hunt"
        width={width}
        tooltipContent={
          <>
            <p>
              The next challenge is available at midnight UTC daily. If you run
              into issues, send a screenshot with a description to the Hacktober
              team.
            </p>
            <i className="smaller-tooltip-text">
              Please make sure to follow our{" "}
              <a
                href="https://twodegrees1.sharepoint.com/teams/InformationSecurityGovernance/SitePages/Chat-GPT-Use.aspx"
                aria-label="Generative AI Acceptable Use Policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Generative AI Acceptable Use Policy
              </a>{" "}
              when interacting with ChatGPT. This instance of ChatGPT is limited
              to Slalom Hacktober security topics, and has been trained
              specifically on our code hunt questions. This is not a broad-use
              instance of ChatGPT.
            </i>
          </>
        }
      ></SectionHeader>

      {/* Description */}
      {width > TABLET_MAX && (
        <Card containerClass="code-hunt-description">
          <div className="points-container">
            <img
              src={databaseWhite}
              alt="database-icon"
              className="database-icon"
            />
            2400 points
          </div>
          <p>
            Test your security chops by tackling a challenge from the digital
            code hunt! Each day, users will be permitted to take one challenge
            from the grid/board. The board will consist of three difficulty
            levels, the harder the level the more points you will get. Beat all
            challenges to accumulate the max amount of points. Remember, the
            grid/board security system only permits one submission per day. So
            fear not, venture into the digital world each day for an opportunity
            to champion a challenge and destroy the grid.
          </p>
        </Card>
      )}

      <div className="code-hunt-bg">
        {/* BG Cogs */}
        {renderBg()}

        {
          // Animations view
          showingAnimation ? (
            <video
              className="code-hunt-video"
              src={correctAnswer ? successAnimation : failAnimation}
              onEnded={() => {
                setShowingAnimation(false);
                queryCache.invalidateQueries(["userBoard"]);
              }}
              autoPlay={true}
            ></video>
          ) : // Question view
          honeycombId !== -1 ? (
            <CodeHuntQuestionView
              answer={answer}
              setHoneycombId={setHoneycombId}
              honeycombId={honeycombId}
              challengeData={challengeData}
            ></CodeHuntQuestionView>
          ) : // Board view
          width > TABLET_MAX ? (
            !userBoardLoading ? (
              <CodeHuntBoard
                completed={completed}
                available={available}
                pointClick={pointClick}
              ></CodeHuntBoard>
            ) : (
              <LoadingSpinner
                isLoading={userBoardLoading}
                size="big"
              ></LoadingSpinner>
            )
          ) : (
            // Non desktop warning
            <div className="code-hunt-non-desktop">
              <strong>ATTENTION!:</strong>&nbsp;There’s more points to be gained
              in the Digital Code Hunt, but this feature is only availabe on the
              desktop version of the site. Make sure you don’t miss this
              opportunity and jump over to your laptop when you can.
            </div>
          )
        }
      </div>
    </>
  );
};

export default CodeHunt;
