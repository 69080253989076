import React, { useState, FC } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import DropdownFilter from "../Dropdown";
import { getMarkets, getParticipants } from "../../ApiHelper";
import { sortedRankedPartcipants } from ".";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import "./Leaderboard.scss";
import { ELeaderboardOption } from "./Leaderboard.util";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import Card from "../Card/Card";
import Securyeti from "../Securyeti/Securyeti";
import { IParticipant } from "../../models/participant.model";
import LeaderboardOptions from "./LeaderboardOptions/LeaderboardOptions";
import LeaderboardOrFallback from "./LeaderboardOrFallback/LeaderboardOrFallback";
import SectionHeader from "../SectionHeader/SectionHeader";

const { MOBILE_MAX } = deviceWidthBreakpoints;

const leaderBoardContainerResponsiveClassName = (width) =>
  `leaderboard-container leaderboard-container-${getResponsiveClassName(
    width
  )}`;

const sectionTitleString = (isLocalLeaderBoard) =>
  isLocalLeaderBoard ? "Local Winners" : "Participant Leaderboard";

const renderMobileLeaderBoardOptions = (
  width: number,
  setSelectedOption: (selectedOption: ELeaderboardOption) => void,
  selectedOption: ELeaderboardOption
) => {
  const leaderboardOptions = [
    { text: "Global", value: ELeaderboardOption.Global },
    { text: "Office", value: ELeaderboardOption.Market },
    { text: "Local", value: ELeaderboardOption.Local },
  ];

  return width <= MOBILE_MAX ? (
    <div className="leaderboard-options-mobile">
      <DropdownFilter
        placeholder="Global"
        defaultValue={selectedOption}
        onChange={(event, data) =>
          setSelectedOption(data.value as ELeaderboardOption)
        }
        options={leaderboardOptions}
        selection
        label=""
      />
    </div>
  ) : null;
};

type LeaderboardProps = {
  isLocalLeaderBoard: Boolean;
  isWinnersLeaderBoard: Boolean;
  participantData: IParticipant;
};

const Leaderboard: FC<LeaderboardProps> = ({
  isLocalLeaderBoard = true,
  isWinnersLeaderBoard,
  participantData,
}: LeaderboardProps) => {
  const { width } = useWindowSize();
  const [selectedOption, setSelectedOption] = useState<ELeaderboardOption>(
    ELeaderboardOption.Global
  );
  const {
    data: globalData,
    error: globalError,
    isLoading: globalIsLoading,
  } = useQuery(["global", "Global"], getParticipants);
  const {
    data: localData,
    error: localError,
    isLoading: localIsLoading,
  } = useQuery(["local", participantData?.market], getParticipants);

  const {
    data: marketsData,
    error: marketsError,
    isLoading: marketsIsLoading,
  } = useQuery(["markets"], getMarkets);

  let participants = null;
  let error = null;
  let isLoading = null;
  if (selectedOption === ELeaderboardOption.Global) {
    participants = globalData;
    error = globalError;
    isLoading = globalIsLoading;
  } else if (selectedOption === ELeaderboardOption.Market) {
    participants = marketsData;
    error = marketsError;
    isLoading = marketsIsLoading;
  } else if (selectedOption === ELeaderboardOption.Local) {
    participants = localData;
    error = localError;
    isLoading = localIsLoading;
  }

  const sortedParticipants =
    participants && sortedRankedPartcipants(participants);

  return (
    <div className="leaderboard-section section-container">
      <SectionHeader
        sectionTitleString={sectionTitleString(isLocalLeaderBoard)}
        width={width}
        tooltipContent={
          <p className="leaderboard-tooltip">
            This leaderboard updates in real time. To learn more, check out the{" "}
            <Link to="/faqs">FAQ page</Link>.
          </p>
        }
      ></SectionHeader>
      <Securyeti />
      {width > MOBILE_MAX && (
        <LeaderboardOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}
      <Card>
        <div
          className={`${leaderBoardContainerResponsiveClassName(
            width
          )} ${selectedOption}-container`}
        >
          {width <= MOBILE_MAX &&
            renderMobileLeaderBoardOptions(
              width,
              setSelectedOption,
              selectedOption
            )}
          <LeaderboardOrFallback
            selectedOption={selectedOption}
            isLocalLeaderBoard={isLocalLeaderBoard}
            isWinnersLeaderBoard={isWinnersLeaderBoard}
            sortedParticipants={sortedParticipants}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </Card>
    </div>
  );
};

export default Leaderboard;
