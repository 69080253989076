import React from "react";
import { useContext } from "react";
import EventCardGrid, {
  EventCardGridProps,
  RelativeTime,
} from "./EventCardGrid";
import { ParticipantContext } from "../../App";

type PastEventCardGridProps = Omit<
  EventCardGridProps,
  "selectedTopics" | "selectedTimes" | "speakerProfiles" | "setSpeakerProfiles"
>;

// PastEventCardGrid is a wrapper for EventCardGrid with filter set to RelativeTime.PAST
// selectedDays, selectedTimes, speakerProfiles, and setSpeakerProfiles are not used in PastEventCardGrid
const PastEventCardGrid: React.FC<PastEventCardGridProps> = ({
  eventsAreLoading,
  eventsError,
  eventsData,
  selectedDays,
  selectedMarkets,
}): React.ReactElement => {
  const participant = useContext(ParticipantContext);

  if (eventsData && participant?.eventLikes?.length) {
    eventsData.forEach((event) => {
      event.likeCategories.forEach((likeCategory) => {
        likeCategory.liked = participant.eventLikes.some(
          (eventLike) => eventLike.activityLikeId === likeCategory.likeId
        );
      });
    });
  }

  return (
    <EventCardGrid
      eventsAreLoading={eventsAreLoading}
      eventsError={eventsError}
      eventsData={eventsData}
      selectedMarkets={selectedMarkets}
      selectedDays={selectedDays}
      selectedTimes={[]}
      speakerProfiles={[]}
      setSpeakerProfiles={() => {}}
      relativeTimeFilter={RelativeTime.PAST}
    />
  );
};

export default PastEventCardGrid;
