import React from "react";
import "./AboutPage.scss";
import AboutInfoList from "../../components/AboutInfoList/AboutInfoList";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import { getResponsiveClassName } from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import Card from "../../components/Card/Card";
import { Event } from "../../utils/consts/event";
import AboutPartnersList from "../../components/AboutPartnersList/AboutPartnersList";
import aboutBannerDesktop from "../../assets/images/headers/about/about-hero-image.png";
import aboutBannerTablet from "../../assets/images/headers/about/about-hero-image-tablet.png";
import aboutBannerMobile from "../../assets/images/headers/about/about-hero-image-mobile.png";
import LinkButton from "../../components/LinkButton/LinkButton";
import SectionHeader from "../../components/SectionHeader/SectionHeader";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

const getAboutHeaderResponsiveClass = (width) =>
  `about-header-${getResponsiveClassName(width)}`;

const getAboutWelcomeSectionResponsiveClass = (width) =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = (width) =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const event = Event;

const AboutPage = () => {
  const { width } = useWindowSize();
  const aboutBanner =
    width > TABLET_MAX
      ? aboutBannerDesktop
      : width > MOBILE_MAX
      ? aboutBannerTablet
      : aboutBannerMobile;
  const url =
    "https://sparkthink.slalom.com/survey/s/134e7c90-a77d-48c0-a091-f3eb6fb8870f";

  return (
    <div>
      <ScrollToTop>
        <PageWrapper bannerImage={aboutBanner}>
          <div className="about-container">
            <section
              className={`welcome-section ${getAboutContactSectionResponsiveClass(
                width
              )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
            >
              <div>
                <SectionHeader
                  sectionTitleString={
                    event.aboutPage.noteFromPrismaticaTeamHeader
                  }
                  width={width}
                ></SectionHeader>
                <Card
                  containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                    width
                  )}`}
                >
                  <div className="contact-section-title">
                    Thank you for participating!
                  </div>
                  <div className="about-page-text-body-container">
                    {event.aboutPage.noteFromPrismaticaTeam}
                  </div>
                </Card>
              </div>
            </section>
            <section
              className={`welcome-section welcome-section-about-card ${getAboutContactSectionResponsiveClass(
                width
              )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
            >
              <Card
                containerClass={`contact-card contact-card-${getResponsiveClassName(
                  width
                )}`}
              >
                <div>
                  <div className="contact-section-title">Got feedback?</div>
                  <div className="about-page-text-body-container">
                    {event.aboutPage.aboutEventsFeedbackText}
                  </div>
                </div>
                <div></div>
                <div className="card-bottom-button-container">
                  <LinkButton
                    href="mailto:zenith@slalom.com"
                    newTab={true}
                    text="Contact Us"
                  />
                </div>
              </Card>
              <Card
                containerClass={`contact-card contact-card-${getResponsiveClassName(
                  width
                )}`}
              >
                <div>
                  <div className="contact-section-title">
                    Join the Events Platform Build Team!
                  </div>
                  <div className="about-page-text-body-container">
                    {event.aboutPage.aboutEventsJoinText}
                  </div>
                </div>
                <div></div>
                <div className="card-bottom-button-container">
                  <LinkButton href={url} newTab={true} text="Join Us" />
                </div>
              </Card>
              <Card
                containerClass={`contact-card contact-card-${getResponsiveClassName(
                  width
                )}`}
              >
                <div>
                  <div className="contact-section-title">Caught a bug?</div>
                  <div className="about-page-text-body-container">
                    {event.aboutPage.aboutEventsBugText}
                  </div>
                </div>
                <div></div>
                <div className="card-bottom-button-container">
                  <LinkButton
                    href="mailto:zenith@slalom.com"
                    newTab={true}
                    text="Contact Us"
                  />
                </div>
              </Card>
            </section>

            <section className="Partners-section">
              <SectionHeader
                sectionTitleString={"Partners"}
                width={width}
              ></SectionHeader>
              <AboutPartnersList />
            </section>
            {Object.keys(event.aboutPage.aboutTeams).map((team, idx) => {
              return (
                <div key={idx}>
                  <div>
                    <div
                      className={`about-header ${getAboutHeaderResponsiveClass(
                        width
                      )}`}
                    >
                      <SectionHeader
                        sectionTitleString={
                          team === "Ownership"
                            ? "Solution Ownership"
                            : team === "Quality"
                            ? "Quality Engineering"
                            : team === "Management"
                            ? "Program Management"
                            : team === "Design"
                            ? "Experience Design"
                            : team === "InfoSec"
                            ? "InfoSec Team"
                            : team
                        }
                        width={width}
                      ></SectionHeader>
                    </div>
                    <AboutInfoList
                      participants={event.aboutPage.aboutTeams[team]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </PageWrapper>
      </ScrollToTop>
    </div>
  );
};

export default AboutPage;
