import React from "react";
import "./CodeHuntPoint.scss";
import CodeHuntPointComplete from "../../../../assets/images/code-hunt/code-hunt-point-complete.png";
import CodeHuntPoint100 from "../../../../assets/images/code-hunt/code-hunt-point-100.png";
import CodeHuntPoint200 from "../../../../assets/images/code-hunt/code-hunt-point-200.png";
import CodeHuntPoint300 from "../../../../assets/images/code-hunt/code-hunt-point-300.png";
import { useQuery } from "react-query";
import {
  getCodeHuntChallengeById,
  getCodeHuntChallengeByLevel,
} from "../../../../ApiHelper";

type Props = {
  honeycombId: number;
  type: number;
  completed: boolean;
  available: boolean;
  pointClick: (honeycombId: number, challenge: any) => void;
  challengeId?: number;
};

const CodeHuntPoint: React.FC<Props> = ({
  honeycombId,
  type,
  completed,
  available,
  pointClick,
  challengeId,
}): React.ReactElement => {
  const {
    data: challengeData,
    error: challengeError,
    isLoading: challengeLoading,
  } = useQuery<any>(
    [
      "challenge",
      challengeId ? challengeId : type === 100 ? 8 : type === 200 ? 9 : 10,
    ],
    challengeId ? getCodeHuntChallengeById : getCodeHuntChallengeByLevel,
    { staleTime: Infinity }
  );

  return (
    <div className="code-hunt-point">
      {/* Hexagon used for hovering and clicking */}
      <div
        className={`hexagon ${completed ? "" : "incomplete"} ${
          available ? "available" : ""
        }`}
        onClick={
          !challengeLoading && !challengeError
            ? () => pointClick(honeycombId, challengeData)
            : null
        }
      ></div>
      {/* Point image */}
      {completed ? (
        <img src={CodeHuntPointComplete} alt="code-hunt-point"></img>
      ) : type === 100 ? (
        <img src={CodeHuntPoint100} alt="code-hunt-point"></img>
      ) : type === 200 ? (
        <img src={CodeHuntPoint200} alt="code-hunt-point"></img>
      ) : (
        <img src={CodeHuntPoint300} alt="code-hunt-point"></img>
      )}
    </div>
  );
};

export default CodeHuntPoint;
