import React from "react";
import "./HackerPage.scss";
import {
  getResponsiveBackgroundClass,
  getMainContainerClass,
  getPageContainerClass,
  getResponsiveClassName,
} from "../../utils/responsiveClassHelpers";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import ScrollToTop from "../../components/ScrollToTop";
import useWindowSize, {
  deviceWidthBreakpoints,
} from "../../utils/useWindowSize";
import HackathonsSection from "../../components/HackathonsSection/HackathonsSection";
import databaseWhite from "../../assets/images/database-white.svg";
import Card from "../../components/Card/Card";
import homeBannerDesktop from "../../assets/images/headers/hacker/hacker-hero-image.png";
import homeBannerTablet from "../../assets/images/headers/hacker/hacker-hero-image-tablet.png";
import homeBannerMobile from "../../assets/images/headers/hacker/hacker-hero-image-mobile.png";
import SpotTheFake from "../../components/SpotTheFake/SpotTheFake";
import GalleryImage from "../../assets/images/AR-time-capsule.png";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CodeHunt from "../../components/CodeHunt/CodeHunt";
import { eventHasEnded } from "../../utils/eventUtil";

const getInnerContainerClass = (width) =>
  `gallery-qr-bar-${getResponsiveClassName(width)}`;

// const getDescriptionContainerClass = (width) =>
//   `description-container-${getResponsiveClassName(width)}`;

const getAboutWelcomeSectionResponsiveClass = (width) =>
  `welcome-section-${getResponsiveClassName(width)}`;

const getAboutContactSectionResponsiveClass = (width) =>
  `contact-section-container-${getResponsiveClassName(width)}`;

const HackerPage: React.FC = (): React.ReactElement => {
  const { width } = useWindowSize();
  const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

  const bannerImg =
    width > TABLET_MAX
      ? homeBannerDesktop
      : width > MOBILE_MAX
      ? homeBannerTablet
      : homeBannerMobile;

  const showCodeHunt = true;

  return (
    <ScrollToTop>
      <div className="home-page-container">
        <PageWrapper bannerImage={bannerImg}>
          <div
            className={`app-page section-container ${getResponsiveBackgroundClass(
              width
            )}`}
          >
            <div className={getPageContainerClass(width)}>
              <div className={getMainContainerClass(width)}>
                {/* <section
                className={`welcome-section ${getAboutContactSectionResponsiveClass(
                  width
                )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
              >
                <div>
                  <div className="section-title small">
                    {event.aboutPage.noteFromPrismaticaTeamHeader}
                  </div>
                  <Card
                    containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                      width
                    )}`}
                  >
                    <div className="contact-section-title">
                      Thank you for participating!
                    </div>
                    <div className="about-page-text-body-container">
                      {event.aboutPage.noteFromPrismaticaTeam}
                    </div>
                  </Card>
                </div>
                <div>
                  <div className="section-title small section-title-last">
                    {event.aboutPage.noteFromInfoSecTeamHeader}
                  </div>
                  <Card
                    containerClass={`contact-card contact-card-wide-${getResponsiveClassName(
                      width
                    )}`}
                  >
                    <div className="about-page-text-body-container">
                      {event.aboutPage.noteFromInfoSecTeam}
                    </div>
                  </Card>
                </div>
              </section> */}
                {!eventHasEnded && (
                  <>
                    <SectionHeader
                      sectionTitleString={"Spot The Fake"}
                      width={width}
                    ></SectionHeader>
                    <Card containerClass="spot-the-fake-description">
                      <div className="points-container">
                        <img
                          src={databaseWhite}
                          alt="database-icon"
                          className="database-icon"
                        />
                        200 points
                      </div>
                      <p>
                        Do you have what it takes to tell AI generated art from
                        Human art? Each business day, you&apos;ll be able to
                        showcase your GenAI literacy by completing the Spot the
                        Fake challenge! The art refreshes every 24 hours, so
                        don&apos;t miss out!
                      </p>
                    </Card>
                    <section
                      className={`welcome-section contact-section ${getAboutContactSectionResponsiveClass(
                        width
                      )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
                    >
                      <div
                        className={`spot-the-fake-bar ${getInnerContainerClass(
                          width
                        )}`}
                      >
                        <SpotTheFake />
                      </div>
                    </section>

                    {/* Adding hackathons section */}
                    <HackathonsSection />

                    <section
                      className={`gallery-qr-description-container ${getAboutContactSectionResponsiveClass(
                        width
                      )} ${getAboutContactSectionResponsiveClass(width)}`}
                    >
                      <SectionHeader
                        sectionTitleString={"AR Time capsule"}
                        width={width}
                      ></SectionHeader>
                      <div className="ar-section">
                        <Card containerClass="ar-description">
                          <img
                            src={GalleryImage}
                            alt="AI Time capsule"
                            className="ai-image"
                          />
                          <div className="points-container">
                            <img
                              src={databaseWhite}
                              alt="database-icon"
                              className="database-icon"
                            />
                            200 points
                            <p>
                              Scan the QR code with your phone or tablet and
                              follow the onscreen instructions to access our AR
                              Time Capsule! Make sure to scan this Hacktober
                              image to access. Ask our AI assistant for a
                              special code to earn some points!
                              <br />
                              <span className="bold">TIP!</span> The AR Gallery
                              works best when used on Safari.
                            </p>{" "}
                          </div>
                        </Card>
                      </div>
                    </section>
                  </>
                )}

                {showCodeHunt ? (
                  <CodeHunt />
                ) : (
                  <>
                    <SectionHeader
                      sectionTitleString={"Digital Code Hunt"}
                      width={width}
                    ></SectionHeader>

                    <section
                      className={`codehunt-section welcome-section-about-card ${getAboutContactSectionResponsiveClass(
                        width
                      )} ${getAboutWelcomeSectionResponsiveClass(width)}`}
                    >
                      <Card
                        containerClass={`contact-card contact-card-${getResponsiveClassName(
                          width
                        )}`}
                      >
                        <div className="hacker-page-text-body-container">
                          <p>
                            <span className="bold">ATTENTION!:</span> The
                            Digital Code Hunt is coming soon! Check back next
                            week to play and ask our Securyeti Clippy some
                            questions. 👀
                          </p>
                        </div>
                      </Card>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </PageWrapper>
      </div>
    </ScrollToTop>
  );
};
export default HackerPage;
