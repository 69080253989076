import React, { useContext, useState } from "react";
import "./ChatBot.scss";
import Avatar from "./ChatAvatar/ChatAvatar";
import ChatWindow from "./ChatWindow/ChatWindow";

import { ParticipantContext } from "../../App";

const ChatBot: React.FC = (): React.ReactElement => {
  const [visible, setVisible] = useState(false);
  const participant = useContext(ParticipantContext);

  return (
    <div className="chat-container">
      <ChatWindow
        visible={visible}
        setVisible={setVisible}
        participantData={participant}
        participantIsLoading={!participant}
      ></ChatWindow>
      <Avatar
        onClick={(prevState) => setVisible(!prevState)}
        visible={visible}
      ></Avatar>
    </div>
  );
};
export default ChatBot;
